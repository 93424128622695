import Popup from 'reactjs-popup';
import React, { useState } from 'react';
import 'reactjs-popup/dist/index.css';
import crossButtonIconUrl from '../../../img/svg/cross_button_icon.svg';
import './PopupInfo.css';
import { Tr } from '../../../translations/translation';

const ErrorPopupInfo = ( { errorTitle, errorMessage, setErrorMessage } ) => {
  const [open, setOpen] = useState(true);

  const closeModal = () => {
    setErrorMessage("");
    setOpen(false);
  }
    
  const infoContainer = () => {
    return (
      <div className=''>
        <br />
        <br />
        <h2 className='text-lg font-bold mb-2'>{errorTitle}</h2>
        <p className='mb-5'>{errorMessage}</p>
        <button type='button' className='submit-button close-button ' onClick={closeModal}>{Tr("close")}</button>
      </div>
    );
  }

  return (
    <div>
      <Popup open={open} closeOnDocumentClick onClose={closeModal} contentStyle={{width: "500px", height:"auto"}}>
        <div className="error-popup">
          <a className="close" onClick={closeModal}>
            <img className='cross-button' src={crossButtonIconUrl} alt="cross" />
          </a>
          {infoContainer()}
        </div>
      </Popup>
    </div>
  );
}

export default ErrorPopupInfo;
