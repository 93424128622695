import React from 'react';
import './Footer.css';
import PopupInfo from '../../components/Popups/PopupInfo';

const Footer: React.FC = () => {
  return (
    <footer className='footer'>
      <div className='footer-content'>
        <p>&copy; {new Date().getFullYear()} Bike hotel planner - by  
          <a href='https://techlara.se/' target="_blank" rel="noopener noreferrer"> Techlära </a>
           - &nbsp;
        </p>
        {" "} <PopupInfo type="privacy_policy" isWide={true} />
      </div>
    </footer>
  );
};

export default Footer;
