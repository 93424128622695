import React, { useEffect, useState } from "react";
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import crossButtonIconUrl from '../../img/svg/cross_button_icon.svg';
import PopupInfo from './Popups/PopupInfo';
import { Tr } from "../../translations/translation";
import "./PlacesTextField.css";

interface PlacesTextFieldProps {
  hotelInfoIn: HotelInfo | null;
  townName: string;
  coordinates: {
    lat: number;
    lng: number;
  };
  onHotelSaved: (hotelInfo: HotelInfo) => void;
  onHotelDeleted: (hotelInfo: HotelInfo) => void;
}

interface HotelInfo {
  town: string;
  name: string;
  coordinates: {
    lat: number;
    lng: number;
  };
}

const PlacesTextField = ({ hotelInfoIn, townName, coordinates, onHotelSaved, onHotelDeleted }: PlacesTextFieldProps) => {
  const placesLib = useMapsLibrary("places");
  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken>();
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(
    null
  );
  const [inputValue, setInputValue] = useState(hotelInfoIn ? hotelInfoIn.name : "");
  const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [holdDownId, setHoldDownId] = useState("");
  const [currentHotelInfo, setCurrentHotelInfo] = useState<HotelInfo | null>(hotelInfoIn ? hotelInfoIn : null);

  useEffect(() => {
    if (!placesLib) return;

    const div = document.createElement("div");
    setPlacesService(new placesLib.PlacesService(div));
    setSessionToken(new placesLib.AutocompleteSessionToken());
  }, [placesLib]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleHoldDown = (placeId: string) => {
    setHoldDownId(placeId);
  }

  const handleReleased = (townName: string, placeId: string) => {
    getDetailsAndSetHotel(townName, placeId);
  }

  const handlePredictionClick = (townName: string, placeId: string) => {
    getDetailsAndSetHotel(townName, placeId);
  };

  const onHandleDelete = () => {
    setCurrentHotelInfo(null);
    setInputValue("");
    if (currentHotelInfo) {
      onHotelDeleted(currentHotelInfo);
    }
  }

  const saveHotelToDataBase = () => {
    if (currentHotelInfo) {
      onHotelSaved(currentHotelInfo);
    }
  }

  const getDetailsAndSetHotel = (townName: string, placeId: string) => {
    console.log("town", townName);
    if (!placesService) return;
    const request = {
      placeId,
      fields: ["name", "formatted_address", "geometry", "type"], 
      //fields: ["name", "formatted_address", "geometry", "rating", "opening_hours", "photos", "type"],
    };

    placesService.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && place) {
        setInputValue(place.name || "");
        setCurrentHotelInfo({
          town: townName,
          name: place.name || "", 
          coordinates: { lat: place.geometry?.location?.lat() || 0, lng: place.geometry?.location?.lng() || 0 } 
        });
      } else {
        console.log("Failed to fetch place details:", status);
      }
    });

    setHoldDownId("");
    setPredictions([]);
  }

  useEffect(() => {
    if (!placesService || !inputValue || currentHotelInfo !== null) return;

    if (!coordinates) return;
    const mapCenter = new google.maps.LatLng(coordinates.lat, coordinates.lng);

    const autocompleteService = new google.maps.places.AutocompleteService();
    
    const restrictions = new google.maps.LatLngBounds(
      new google.maps.LatLng(coordinates.lat - 0.03, coordinates.lng - 0.03),
      new google.maps.LatLng(coordinates.lat + 0.03, coordinates.lng + 0.03)
    )
    const request = {
      location: mapCenter,
      componentRestrictions: { country: "se" },
      radius: 10,
      locationRestriction: restrictions,
      //locationBias: {radius: 50, center: coordinates},
      type: "lodging",
      input: inputValue,
      sessionToken,
    };

    autocompleteService.getPlacePredictions(request, (predictions, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
        setPredictions(predictions);
      } else {
        console.log("Failed to fetch predictions:", status);
      }
    });
  }, [placesService, inputValue, sessionToken, currentHotelInfo]);

  const yourHotel = Tr("hotel_in") + townName
  return (
    <div className="text-field-prediction-container">
      <div className='mt-4 flex row'>
            {Tr("hotel_add_hotel")}
            <PopupInfo type="places_textfield" hasButtonTitle={false} />  
          </div>
          
      <div className="flex row">
        <input
          className="text-field places-textfield mr-2"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder={yourHotel}
          disabled={currentHotelInfo !== null}
        />
        
        {currentHotelInfo &&
          <button
            className="close-button mr-3"
            onClick={() => onHandleDelete()}
          >
            <img className='cross-button' src={crossButtonIconUrl} alt="cross" />
          </button>
        }

      </div>
      {currentHotelInfo && 
        <button
          className="submit-button mt-2"
          onClick={saveHotelToDataBase}
        >
          {Tr("save_hotel_on_map")}
        </button>
      }
      <ul className="mb-2">
      {predictions.map((prediction) => (
          <li 
            className={`mb-2 ${holdDownId === prediction.place_id ? "bg-blue-500" : ""}`}
            key={prediction.place_id}
            onClick={() => handlePredictionClick(townName, prediction.place_id)}
            onMouseDown={() => handleHoldDown(prediction.place_id)}
            onMouseUp={() => handleReleased(townName, prediction.place_id)}
          >
            {prediction.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PlacesTextField;