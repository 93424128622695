import { useState, useEffect } from 'react';
import ResponseView from '../Main/ResponseView';
import BikeReservation from './BikeReservation';
import OtherReservation from './OtherReservation';

const SecondContainerHandler = ( { townsIn, menuData, setIsOrdering }) => {
  const [state, setState] = useState(1);
  const [hasOrdered, setHasOrdered] = useState(true);
  const [bookingData, setBookingData] = useState(menuData);

  const MAIN_MENU = 0;
  const BIKE_RESERVATION = 1;
  const OTHER_RESERVATION = 2;
  const RESPONSE = 3;

  useEffect(() => {
    setNumberOfNightsAndDays()
    //loadBookingDataFromLocalStorage()
    setBookingData(prevBookingData => ({...prevBookingData, 
      startDate: menuData.startDate,
      endDate: menuData.endDate,
      adults: menuData.adults,
      rooms: menuData.rooms,
      from: menuData.from,
      to: menuData.to,
      towns: townsIn
    }))
  }, [townsIn, menuData])

  const setNumberOfNightsAndDays = () => {
    const numberOfNights = townsIn.length-2
    const numberOfDays = townsIn.length-1
    setBookingData({...bookingData, numberOfNight: numberOfNights, numberOfDays: numberOfDays})
  }

  const storeBookingDataInLocalStorage = () => {
    localStorage.setItem("bookingData", JSON.stringify(bookingData));
  }

  const loadBookingDataFromLocalStorage = () => {
    const storedBookingData = localStorage.getItem("bookingData");
    const bd = storedBookingData ? JSON.parse(storedBookingData) : null;
    console.log("bd", bd)
    if (bd) {
      setBookingData(bd);
    }
  }

  const currentView = () => {
    if (state === BIKE_RESERVATION) {
      return <BikeReservation 
        bookingData={bookingData}
        setBookingData={setBookingData}
        onSkip={handleSkip} 
        onContinue={handleContinuePressed} 
        onBack={onBack}
        />
    } else if (state === RESPONSE) {
      return <ResponseView 
        hasOrdered={hasOrdered} 
        towns={townsIn}
        onBack={onBack} 
        bookingData={bookingData}
        setBookingData={setBookingData}
        />
    } else if (state === OTHER_RESERVATION) {
      return <OtherReservation 
        onSkip={handleSkip} 
        onBikeReservationFinished={handleBikeReservationFinished} 
        onBack={onBack} 
        bookingData={bookingData}
        setBookingData={setBookingData}
        />
    }
  }

  const handleSkip = () => {
    setHasOrdered(false);
    setState(RESPONSE);
  }

  const handleBikeReservationFinished = () => {
    setState(RESPONSE);
  }

  const handleContinuePressed = () => {
    setState(OTHER_RESERVATION);
  }

  const onBack = (backToState) => {
    if (backToState === MAIN_MENU) {
      setIsOrdering();
    } else {
      setState(backToState);
    }
  }

  storeBookingDataInLocalStorage()

  return(
    currentView()
  );
}

export default SecondContainerHandler;
