import { Tr } from "../../translations/translation.tsx";

const infoData = [
  {
    type: "reserv",
    title: Tr('reservation_info_title'),
    text: Tr('reservation_info_text')
  },
  {
    type: "trip_info",
    title: Tr("planner_info_title"),
    text: Tr("planner_info_text")
  },
  {
    type: "places_textfield",
    title: Tr("places_info_textfield_title"),
    text: Tr("places_info_textfield_text")
  },
  {
    type: "book_hotel_info",
    title: Tr("book_hotel_info_title"),
    text: Tr("book_hotel_info_text")
  },
  {
    type: "privacy_policy",
    title: Tr("privacy_policy_title"),
    text: Tr("privacy_policy_text")
  }
]

export default infoData;