import { useState, useEffect } from 'react';
import { db } from '../../../api/firebase';
import { doc, getDoc } from 'firebase/firestore';
import Map from '../../../views/Main/Map';
import { SyncLoader } from 'react-spinners';
import RouteView from '../Result/RouteView';
import HotelsView from '../Result/HotelsView';
import { Tr } from '../../../../translations/translation';
import "./DisplayResultView.css";
import ErrorPopup from '../../../components/Popups/ErrorPopup';
import { showMap } from '../../../components/TestHelper';
import PopupInfo from '../../../components/Popups/PopupInfo';

const DisplayResultView = () => {
  const [email, setEmail] = useState("");
  const [menuType, setMenuType] = useState(0);
  const [bookingData, setBookingData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const MENU_TYPE_HOTELS = 0;

  useEffect(() => {
    setIsLoading(true);
    getMailFromUrl().then(email => {
      setEmail(email);
      fetchUserData(email);
    });
  }, []);
 
  const getMailFromUrl = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = await urlParams.get('email');
    setEmail(emailParam);
    return emailParam;
  }

  const fetchUserData = async (email) => {
    const docRef = doc(db, "bikePlans", email);
    const docSnap = await getDoc(docRef);
    if (docSnap.data() && docSnap.exists) {
      const data = JSON.parse(docSnap.data().bookingData);
      setBookingData(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const loadingView = () => {
    return(
      <div>
        <h3>Laddar ner din reseplan...</h3>
        <SyncLoader color="#36d7b7" />
      </div>
    );
  }

  const displayItinerary = () => {
    console.log("bookingData", bookingData);
    return (
      <div className='display-result-container'>
        <div className='top-container'>
          <h1 className='text-big font-bold mt-2'>{Tr("result_title")}</h1>
          <label className='text-sm'>{Tr("result_text")}</label>
        </div>
        <br />
        {showMap && <Map hotelsInfo={bookingData.hotelsInfo} from={bookingData.from} to={bookingData.to} />}
        <br />
        <br />
        <div className='menu-container'>
          <div className="menu-switcher flex justify-center space-x-20 mb-3">
            <button onClick={() => setMenuType(0)}>
              Hotell
              <div className={menuType === MENU_TYPE_HOTELS ? "indicator hotel-selected" : "indicator hidden"}></div>
            </button>
            <button onClick={() => setMenuType(1)}>
              Ruttbeskrivning
              <div className={menuType === MENU_TYPE_HOTELS ? "indicator hidden" : "indicator route-selected"}></div>
            </button>
            {/* <div className='indicator-container'>
            <div className={menuType === MENU_TYPE_HOTELS ? "indicator left-selected" : "indicator right-selected"}></div>
          </div> */}
          </div>
          
        </div>
        { menuType === MENU_TYPE_HOTELS ? <HotelsView towns={bookingData.towns} bookingData={bookingData} /> : <RouteView towns={bookingData.towns} bookingData={bookingData} /> }
        <br />
      </div>
    )
  }

  return (
    <div>
      {bookingData && displayItinerary() }
      <br />
      {isLoading && loadingView()}
      <br />
    </div>
  );
}

export default DisplayResultView;