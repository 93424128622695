import townsDB from '../../../data/towns';

const townsInRange = (startTown, endTown) => {
  const allTowns = townsDB
  const startIndex = allTowns.findIndex(town => town.name.toLowerCase() === startTown.toLowerCase());
  const endIndex = allTowns.findIndex(town => town.name.toLowerCase() === endTown.toLowerCase());

  if (startIndex === -1 || endIndex === -1) {
    throw new Error("One or both towns not found in the list.");
  }

  const [minIndex, maxIndex] = [startIndex, endIndex].sort((a, b) => a - b);
  return allTowns.slice(minIndex, maxIndex + 1);
}

export default townsInRange;