export const german = {
  "from": "Von",
  "to": "Nach",
  "date": "Datum",
  "time": "Time",
  "h1_title" : "Deine perfekte Radreise auf Kattegattleden",
  "subtitle" : "Genieße deinen Radsemester und spar dein Geld",
  "helmet_title" : "Kattegattleden | Plan deine Radreise",
  "start_date": "Anfangstag",
  "no_adults": "Anzahl Erwachsenen",
  "no_children": "Anzahl Kinder",
  "no_rooms": "Anzahl Zimmer",
  "no_nights" : "Anzahl Nächte",
  "calculate_route": "Route berechnen",
  "back": "Zurück",
  "close": "Schließen",
  "reserve_bikes": "Fahrräder reservieren",
  "comment": "Kommentar",
  "continue": "Weiter",
  "skip": "Überspringen",
  "something_wrong": "Etwas ist schief gelaufen",
  "total_bikes": "Gesamtanzahl Fahrräder",
  "bike_size": "Fahrräder Größe",
  "email": "E-Mail",
  "phone": "Telefon",
  "name": "Name",
  "reservation_info": "Reservierungsinfo",
  "bike_distance": "Fahrräderdistanz",
  "route_info": "Routeninfo",
  "reservation_email_sent": "Ihre Reservierung wurde gesendet",
  "currency": "SEK",
  "reserve_hotel_for": "Reservieren Sie das Hotel für",
  "hotel_in" : "Hotel im ",
  "bike": "Fahrrad",
  "baggage": "Gepäck",
  "accessories": "Zubehör",
  "addons": "Extras",
  "total_addons": "Gesamte Extras",
  "overview" : "Übersicht",
  "total_distance" : "Totalt avstånd",
  "total": "Totalsumme",
  "height" : "Grösse",
  "day": "Tag",
  "route_description" : "Wegbeschreibung",

  "your_booking_for": "Sie buchen Fahrräder für {{params.value1}} personer im Zeitraum {{params.value2}} - {{params.value3}}", 

   //result view
 "result_title": "Ihre Fahrradreise",
 "result_text": "Folgen Sie den untenstehenden Links und buchen Sie Ihr Hotel für jedes Reiseziel gemäß der Liste. Denken Sie daran, immer mit kostenloser Umbuchung und Stornierung zu buchen, falls es schwierig werden sollte, die gewünschten Hotels an den jeweiligen Standorten zu reservieren.",
 "result_input_text": "Füllen Sie bitte Ihre Daten unten aus, um Ihren Reiseplan per E-Mail zu erhalten.",
 "result_send_button": "Als E-mail sendenl",
 "result_bike_order_text": "Sie haben keine Fahrräder reserviert",
 "result_bike_order_button" : "Fahrräder reservieren",

 "hotel_town": "Übernachtung Stadt",
 "hotel_date": "Übernachtung Datum",
 "hotel_link": "Link zum Hotels",
 "hotel_add_hotel": "Fügen Sie Ihre Buchungen hier ein",


 // transport & accessories
 "transport_title": "Gepäcktransport und Zubehör reservieren",
 "total_bike_cost": "Gesamtsumme Fahrräderl",

 //error messages
 "email_error_nothing": "Bitte geben Sie Ihre E-Mail-Adresse ein",
 "email_error_invalid": "Bitte geben Sie eine gültige E-Mail-Adresse ein",
 "phone_error_nothing": "Bitte geben Sie eine gültige Telefonnummer ein",
 "phone_error_invalid": "Bitte geben Sie eine gültige Telefonnummer ein",
 "name_error_nothing": "Bitte geben Sie Ihren Namen ein",
 "result_error_database": "Es tut uns leid, Etwas ist schief gelaufen.",
 "result_email_sent" : "Ihre Reiseplan wurde gesendet",

 // email reservation
 // should always be in swedish to travelshop but other language when contacting other bikeshops
 "email_resevation_bikeshop_title" : "Kundens bokning",
 "email_resevation_bikeshop_infomation" : "Här kommer en reservation från Bike Hotel Planner",
 "email_resevation_bikeshop_contact_title" : "Kundens uppgifter",
 "email_resevation_bikeshop_reservation_title" : "Bokning och detaljer",

 // email customer reservation
 "email_customer_resevation_title": "Bitte die Reservierung Bestätigen",
 "email_customer_resevation_infomation": "", // TODO: FIX
 "email_customer_resevation_contact_title": "Ihre Kontaktinformationen",
 "email_customer_resevation_reservation_title": "Ihr Reiseplan",

// email travel plan
"email_travelplan_title" : "Ihren Reiseplan ", 
"email_travelplan_link_title" : "Hier sehen Sie ihren Reiseplan", 
"email_travelplan_text" : "Ihre Reiseplan wurde erstellt und Sie können ihn über den folgenden Link sehen:", 
"email_greeting" : "Wir wünschen Ihnen einer schöner Fahrradreise.", 


 // info popup text
 "reservation_info_title": "Wie funktioniert die Reservierung?",
 "reservation_info_text": `- Bei der Reservierung von Fahrrädern und Zubehör wird die Buchung an unseren Partner gesendet, der die Fahrräder vor Ort hat.
 - Sie werden die Fahrräder für Sie reservieren und einen sicheren Zahlungslink innerhalb von 48 Stunden senden.
 - Die Reservierung ist erst nach erfolgter Zahlung für Sie verbindlich.
 - Wenn Sie mindestens 7 Tage vor Abreise buchen, versprechen sie die Verfgbarkeit der Fahrräder.
 - Stellen Sie sicher, dass Sie zuerst die Unterkunft buchen, bevor Sie die Fahrräder bezahlen.
 - Nach der Bestätigung der Reservierung erhalten Sie eine Bestätigungs-E-Mail. Ihre Buchung ist nicht abgeschlossen, bis der Fahrradverleih die Reservierung bestätigt haben. Dies kann bis zu 48 Stunden dauern.`,
 "planner_info_title": "Wie funktioniert?",
 "planner_info_text": `Unser Radplaner erleichtert Ihnen die Buchung von Räder, Unterkünfte und Gepäcktransporte für Ihren Radurlaub.`,

 "places_info_textfield_title": "Gebuchten Hotels hinzufügen",
 "places_info_textfield_text": `Um Ihre Reiseroute mit einem markierten Hotel individuell zu gestalten oder wenn Sie den Gepäcktransport ausgewählt haben füllen Sie die Infos bitte aus. .`,

 //info popup booking reservation
 "popup_booking_reservation_text": `Die Anfrage wird an den Anbieter weitergeleitet, der innerhalb von 48 Stunden mit einer Bestätigung der Buchung und einem sicheren Zahlungslink zurückkommt.
 Mit der Zahlung bestätigen Sie die Bestellung und befolgen die mitgesendeten AGB vom Anbieter.
.`,
 "popup_booking_button": "Bestätigen und reservieren. ",


 // bike cart items
 "touring_bike_name": "Tourenrad",
 "touring_bike_info": "Ein Tourenrad ist ein Tourenrad mit einer bequemen Sitzposition, bei der Lenker und Sattel auf gleicher Höhe oder sogar der Lenker über dem Sattel sind. Tourenräder sind speziell für diesen Zweck konzipiert und bieten gute Möglichkeiten zur Gepäckmitnahme. Wenn Sie über die Fahrradgröße nachdenken und zwischen zwei Größen stehen, ist in der Regel die kleinere Größe zu bevorzugen. Ein kleinerer Rahmen ist einfacher zu handhaben und der Lenker kommt näher am Sattel. Dadurch können Sie auch die Sattelhöhe besser auf Sie abstimmen.",
  
 "touring_bike_electric_name": "E-Tourenrad",
  "touring_bike_electric_info": "Das E-Tourenrad ist ein E-Bike, aber sonst mit dem gleichen Verwendungszweck wie das normale Tourenrad.Es hat einen Vorderradmotor und bequemen sitzposition.",

"electric_city_name": "E-Bike 7-Gänge",
"electric_city_info": "Eine komfortable klassische 7-Gang-Fahrrad mit einer sehr bequemen Sitzposition, die Ihnen sicherlich vertraut ist. Hinzu kommt, dass es sich um ein E-Bike mit einem Vorderradmotor handelt, der Ihnen auf Ihrer Reise leicht hilft vorwärts zu kommen.",

"standard_name" : "7-Gang-Stadtrad",
"standard_info" : "Ein klassisches Alltagsfahrrad mit bequemer Sitzposition, 7 Gängen und Rücktrittbremse.",
 
   // accessories cart items
 "bag": "Fahrradtaschen",
 "bag_info": "Bei einer Fahrradtasche handelt es sich um eine wasserdichte Tasche, die 20–25 Litern fasst, die Sie einfach am Gepäckträger aufhängen können. Wenn Sie keinen Gepäcktransport buchen, sondern in einem Hotel oder Ähnlichem übernachten, benötigen Sie wahrscheinlich zwei Taschen pro Person und eine Tasche oben auf dem Gepäckträger.",
 "helmet": "Helme",
 "helmet_info": "Helme gibt es vor Ort in verschiedenen Grössen",
 "child_seat": "Der Kindersitz",
 "child_seat_info": `Der Kindersitz lässt sich an fast allen Fahrrädern montieren und ist für Kinder, die selbstständig sitzen und den Kopf aufrecht halten können, bis maximal 25 kg geeignet. Geben Sie als zusätzliche Information bei der Buchung an, welches Fahrrad über den Kindersitz montiert werden  soll.`,
 "baggage_transport": "Gepäcktransport",
 "baggage_transport_info": `Wenn Sie den Gepäcktransport gebucht haben, stellen wir Ihnen einen Gepäckanhänger für Ihr Gepäckstück zur Verfügung, auf dem Sie Ihre Daten und Nachnamen eintragen können. Wenn Sie wissen, wo Sie übernachten werden, teilen Sie uns dies dort mit im Formular auf der Webseite oder per email an den Fahrradverleih. Die Gepäckanhänger erhalten Sie entweder am ersten Abend an der Rezeption oder bei der Abholung der Leihräder. Ab 9:30 Uhr muss das Gepäck an der dafür vorgesehenen Stelle an der Rezeption deponiert werden. Das Gepäck wird in Ihre nächste Übernachtung am Nachmittag geliefert. Berücksichtigen Sie für die Gesundheit unserer Mitarbeiter das Maximalgewicht von 25 kg.`,
 "baggage_transport_pricetype": "pro Tasche",
 "ts_insurance": "Notfallschutz für Fahrrad",
 "ts_insurance_info": "Mit dem Notfallschutz für das Fahrrad erhalten Sie unterwegs Hilfe falls etwas kaputt geht. Sie sind  bis zu 2.000 SEK versichert. Sie erhalten Zugang zu einem Reparaturset und einer Notfallnummer. Bei Bedarf wird das Fahrrad ausgetauscht. Es ist jedoch nicht garantieren, dass es sich um den gleichen Fahradmodelle handelt.",
 "ts_insurance_pricetype": "SEK für die Periode",


  // route info
  "route_1_info": `Sie fahren Heute über die schöne Kullahalbinsel, die mit Kunst, Hofläden und Flohmärkten gefüllt ist. Die Strecke ist eine Mischung aus sanft gewellten, offenen Feldern und hoher, hügeliger Landschaft mit einer fantastischen Aussicht. Nachdem Sie Helsingborg verlassen haben und nach einem kurzen Anstieg das Schloss und den Schlossgarten von Sofiero erreicht haben, können Sie sich eine Pause gönnen. Besonders wenn Sie im Garten interessiert sind, aber auch wegen des wunderschönen Schlosses. Weiter geht es durch kleine, malerische Dörfer und kleine Häfen wie Domsten und Viken, bevor Sie Höganäs erreichen. Höganäs ist in Schweden wegen der langen Herstellung von Keramik sehr bekannt. Der Weg führt weiter über die schöne und malerische Kullahalbinsel. Am äußersten Ende liegt das Naturschutzgebiet Kullaberg mit dem Kullen-Leuchtturm, ein Abstecher, den Sie nicht bereuen werden. Der höchste Punkt – Håkull – misst 187 Meter über dem Meeresspiegel. Auf der Kullahalbinsel liegt eines der neuen Weinanbaugebiete Schwedens mit mehreren Weingütern, die Sie besuchen können. Wenn Sie wieder das Meer erreichen, passieren Sie die Gemeinden Arild, Skäret, Jonstorp und Norra Häljaröd, und auf der Strecke gibt es mehrere nette Cafés. Hier gibt es auch mehrere Restaurants, Imbisse und Cafés. Verpassen Sie nicht, einen Kaffee bei Flickorna Lundgren auf Skäret zu trinken. Bevor Sie Ängelholm erreichen, radeln Sie durch mehrere kleine, gemütliche Küsten Gemeinden.`,
  "surface_1": "90% Asphalt, 10% Schotter",

  "route_2_info": "Xxxxxxx",
  "surface_2": "76% Asphalt, 24% Schotter",

  "route_3_info": "Dieser Teil des Kattegattleden ist die hügeligste Strecke. Genießen Sie die schöne Aussicht auf das Meer und die abwechslungsreiche Agrarlandschaft der Bjäre-Halbinsel. Der Kattegattleden führt durch das Zentrum von Ängelholm, direkt neben dem Bahn- und Busbahnhof, und leitet Sie weiter hinunter zum schönen Stadtteil Havsbaden. Hier treffen Sie auf Walder, unberührte Natur und einen herrlich breiten und langen Sandstrand. Halten Sie an der Strandkiosk an und probieren Sie Engelholms Glass, die eigene stolze Eismarke der Stadt. Die Fahrt geht weiter entlang der Küste Richtung Skälderviken, durch kleine Dörfer und Fischerdörfer. Sie passieren Skepparkroken und Vejbystrand, bevor Sie in Killebäckstorp nach dem malerischen Torekov abbiegen. Hier gibt es Cafés, Restaurants und Sie können auch die Fähre nach Hallands Väderö nehmen. Von Torekov geht die Tour weiter ins Herz von Bjäre. Hier erwartet Sie eine kleinteilige Agrarlandschaft, typisch für die Bjäre-Halbinsel, geformt und basierend auf Viehzucht seit prähistorischer Zeit. Sie treffen das Meer wieder bei Norrviken Garten und folgen dem Weg weiter zum Hafen von Båstad.",
  "surface_3": "76% Asphalt, 24% Schotter",

  "route_4_info": "Dieser Abschnitt der Route führt durch Schwedens längsten Sandstrand und durch grüne Naturschutzgebiete. Vom Hafen von Båstad folgen Sie dem Meer, bis Sie den Kiefernwald in Hemmeslöv erreichen und die Grenze zwischen Halland und Skåne passieren. Nun erwartet Sie ein leichter Abschnitt entlang des langen Sandstrandes von Laholmsbukten. Im familiären Skummeslövsstrand finden Sie Restaurants und Cafés. Hier ist das Meer flach und die Landschaft offen. Einige Kilometer landeinwärts liegt das schöne Schloss Skottorp und das gemütliche Laholm, die älteste Stadt des Region. Planen Sie etwa 20 Minuten von der Route nach Laholm ein. Einige Kilometer nördlich von Skummeslövsstrand erreichen Sie Mellbystrand, einen Badeort mit Erinnerungen an vergangene Zeiten. Hier sind die Dünen höher und das Meer wilder. Sie passieren Minigolfplätze und Freiluftrestaurants. Die Route führt weiter an Laxvik vorbei und hinaus in die offene Landschaft südlich von Halmstad. Bevor Sie das Meer verlassen, können Sie bei Påarps Klapperstensfeld mit 220 Gräbern aus der Eisenzeit einen Stopp einlegen. Der Weg führt dann durch den Östra-Strand, bevor er das Schloss von Halmstad erreicht.",
  "surface_4": "75% Asphalt, 25% Schotter",

  "route_5_info": `Die Fahrt geht weiter nach Norden zu einem der berühmtesten und schönsten Strände Schwedens, Tylösand. Wenn Sie nicht auf dem von Rettungsschwimmern überwachten Strand baden möchten, empfehle ich Ihnen, das Hotel Tylösand zu besuchen. Im Hotel gibt es Schwedens größte Foto-Kunstgalerie und ein "Museum", das Teile der Geschichte von Gyllene Tider und Roxette zeigt. Die Route folgt der Küste nach Norden an Haverdal und Särdal vorbei. Halten Sie an der Küstenstation Steninge für einen Kaffee und Kuchen im Plätteriet oder Bikebar. Sie müssen nicht weit radeln, bevor Sie Ugglarps Havsbad und Långasand erreichen, beide mit Bademöglichkeiten und Restaurants. Nach einer kurzen Fahrt ins Landesinnere kommen Sie wieder an die Küste und Grimsholmen. Hier ist es ideal für einen Stopp und einen kurzen Spaziergang zum Wasser, vielleicht genießen Sie ein mitgebrachtes Picknick. Sie fahren weiter und sind bald in Falkenberg und Skrea Strand.`,
  "surface_5": "94% Asphalt, 6% Schotter",

  "route_6_info": "Die Strecke Falkenberg-Varberg ist ein leicht befahrbarer Teil des Kattegattleden, nur 40 km lang und ohne erwähnenswerte Steigungen. Sie radeln durch mehrere kleine Gemeinden und Dörfer. Halten Sie in Olofsbo an, um im Restaurant Kvarnen zu essen und zu trinken, bevor Sie zum Naturreservat Morups Tånge radeln, um in dem schönen Leuchtturm die Vögel zu beobachten. Radeln Sie ein Stück weiter nach dem alten Fischerdorf Glommen. In Morup können Sie die Brautkrone Macher Britten Toftarp besuchen. Sie radeln weiter auf einem relativ neu eröffneten Radweg, passieren den Campingplatz Björkäng und in Utterås finden Sie “Rit und Målerigården”. Essen Sie eine Waffel und bewundern Sie die ganze Kunst, vielleicht kaufen Sie etwas und lassen es direkt nach Hause schicken. Sie nähern sich Träslövsläge, einem Fischerdorf mit mehreren Restaurants. Hier müssen Sie die wunderbaren frisch gekochten Kaisergranaten probieren. Die Route geht weiter zur Strandpromenade und wenn Sie die Festung Varberg passiert haben, erreichen Sie den Hafen von Varberg mit dem Kaltbadehaus und dem Gesellschaftspark.",
  "surface_6": "97% Asphalt, 3% Schotter",

  "route_7_info": `Die Route folgt weiterhin der Küste und führt durch Wiesen, Wälder und landwirtschaftliche Felder. Dieser Abschnitt ist leicht hügelig, aber ohne große Steigungen. Kurz nördlich von Varberg liegt das Informationszentrum “Naturum Getterön”, ein Muss für Vogelliebhaber. Sie radeln durch kleine Gemeinden und erreichen bald Bua, ein altes Fischerdorf. Im Hafen gibt es mehrere Lokale, die Fisch und Meeresfrüchte servieren. Die Route führt weiter vorbei am Kernkraftwerk Ringhals, aber auch durch schöne Strandwälder. Sie fahren weiter entlang der Küste und passieren Kärradal, Stråvalla und Frillesås, alle mit ausgezeichneten Restaurants, Cafés und Badestränden. Halten Sie in Åsa und warum nicht ein Eis im Åsa "Glasscafé" genießen? In Åsa geht die Route landeinwärts weiter und Sie radeln auf kleinen Straßen. Kurz südlich von Fjärås können Sie von der Route abweichen und einen Abstecher zum Schloss Tjolöholm machen, Schwedens einziges Schloss im Tudorstil. Sie radeln weiter durch Felder und Pferdehöfe. In Kungsbacka führt die Route durch die Stadt, die ein alter Handelsplatz ist.`,
  "surface_7": "95% Asphalt, 5% Schotter",

  "route_8_info": `Vom Zentrum in Kungsbacka können Sie entweder der Ausschilderung des Kattegattleden folgen und nach Onsala radeln oder dem Radweg entlang der Straße 158 bis Vallda folgen, wo Sie wieder auf den Kattegattleden treffen. Diese alternative Route verkürzt die Strecke nach Göteborg um etwa 20 km. Wenn Sie der Route nach Onsala folgen, radeln Sie entlang des Kungsbacka Fjords. In Onsala können Sie das Boots- und Schifffahrtsmuseum besuchen und mehr über "Lasse i Gatan", den Kaperkapitän des Königs Lars Gathenhielm, erfahren. Fahren Sie weiter nach Gottskär und genießen Sie ein Eis und einen Spaziergang im schönen Hafen. Sie radeln weiter über die Onsala-Halbinsel bis nach Särö. Von Särö radeln Sie auf der alten Bahntrasse der Säröbanan, die entlang der Küste verläuft. Sie passieren viele kleine Gemeinden mit Restaurants, Cafés und viele Bademöglichkeiten. Nördlich von Billdal folgen Sie der Küste und haben einen schönen Blick über den Schärengarten. Radeln Sie hinaus nach Lilla Amundö, strecken Sie die Beine und genießen Sie die Natur. Näher in Göteborg passieren Sie das Kulturreservat Klippan und die Kunsthalle Röda Sten. Am Järntorget biegen Sie in Richtung City ab und die Route verläuft entlang des Kungsparks, um am Drottningtorget bei der Centralstation zu enden.`,
  "surface_8": "98% Asphalt, 2% Schotter",

  "hotel": "Hotel",
  "arrival": "Ankunft",
  "height_cyclist": "Hhe des Fahrrads",

  "book_hotel_info_title" : "So buchen Sie Hotels!",
  "book_hotel_info_text" : `Folgen Sie einen der Links für jedes Reiseziel zur externen Buchungsseite. Buchen Sie dann ein Hotel für jedes Reiseziel. Denken Sie daran, eine stornierbare Option zu wählen, damit Sie nicht plötzlich ohne Unterkunft für eine Etappe dastehen.
  Nachdem Sie das Hotel gebucht haben, kehren Sie auf unsere Seite zurück, tragen Sie das Hotel in die Liste ein und speichern es.
  Nachdem Sie ein Hotel gespeichert haben, können Sie es direkt auf der Karte sehen, wo Sie auch die GPS Route sehen können.
  Sobald alle Hotels gebucht sind, können Sie die Liste an Ihre E-Mail senden, damit Sie sie behalten können.
  Sie können auch zurückgehen und Fahrräder, Zubehör und Gepäcktransporte buchen, wenn Sie das noch nicht getan haben.`,

  "error_baggage_transport" : "Nachdem Sie den Gepäcktransport gebucht haben, müssen Sie jedes gebuchtes Hotel im Formular hinzufügen, um die Karte mit allen Orten zu aktualisieren. Die Informationen werden direkt an den lokalen Anbieter weitergeleitet, damit Sie Ihr Gepäck während der Tour abgeholt und geliefert bekommen können.",
  "height_error" : "Bitte geben Sie die Größe der Personen an, die Fahrrad fahren werden, für jedes Fahrrad.",
  "height_wrong_error" :  "Es scheint als haben Sie eine ungültige Größe eingegeben haben.",
  "privacy_policy_title" : "Unsere Dienstleistungen und Allgemeinen Geschäftsbedingungen",
  "privacy_policy_text" : `Buchung von Fahrrädern und Zubehör
  Was passiert, wenn ich eine Buchungsanfrage für Fahrräder und Zubehör sende? Wenn Sie eine Buchungsanfrage über unsere Website senden, wird diese sofort an den Lieferanten weitergeleitet. Innerhalb von 48 Stunden werden Sie kontaktiert mit Informationen zur Verfügbarkeit, dem endgültigen Preis und Antworten auf eventuelle weitere Fragen. Wenn Sie Ihre Anfrage mindestens 7 Tage vor der Abreise senden, wird der Lieferant Ihre Anfrage bestätigen. Sie können dann die Buchung über einen sicheren Link akzeptieren und bezahlen. Falls Sie es ändern wollen oder ganz abbuchen ist dies auch möglich ohne weitere Kosten.  oder ablehnen, den Sie vom Fahrradlieferanten erhalten.
  Wird es teurer, über Sie zu buchen als direkt beim Veranstalter? Nein, unser Geschäftsmodell basiert darauf, dass wir eine Provision erhalten, wenn Sie über unsere Links buchen oder eine Anfrage für Fahrräder stellen. Weder Sie noch der Veranstalter zahlen zusätzliche Gebühren. Der Preis bleibt derselbe wie bei einer direkten Buchung beim Veranstalter.
  Es ist mehr als 24 Stunden her, seit ich meine Anfrage gesendet habe, und ich habe keine Antwort erhalten. Was soll ich tun? Wenn mehr als 48 Stunden vergangen sind und Sie noch keine Antwort erhalten haben, kontaktieren Sie uns bitte per E-Mail, damit wir Ihnen weiterhelfen können. Überprüfen Sie auch Ihren Spam-Ordner, um sicherzustellen, dass die Antwort nicht dort gelandet ist.
  Wie storniere ich eine Buchung? Für Fahrräder ist die Buchung nur dann bestätigt, wenn Sie über den Link und die Bestätigung des Vermieters bezahlen. Wenn Sie nicht bezahlt haben, haben Sie sich nicht verpflichtet. Die Stornierungsbedingungen variieren je nach Abreisedatum, und Sie erhalten alle Details von Ihrem Lieferanten.
  Welche Dienstleistungen bieten Sie an?
  Wir bieten eine Plattform, über die Sie als Kunde selbstständig Unterkünfte entlang Ihrer Radroute buchen und bezahlen können. Wir helfen auch bei der Weiterleitung von Buchungsanfragen an lokale Anbieter von Mietfahrrädern. Der Anbieter wird innerhalb von 24-48 Stunden auf Ihre Anfrage antworten, und Zahlung und Bedingungen unterliegen der Richtlinie des lokalen Anbieters.
  Wie funktioniert die Hotelreservierung?
  Wir vereinfachen Ihren Prozess, Unterkünfte entlang Ihrer Radroute zu buchen, indem wir Vorschläge von verschiedenen Anbietern auf einer Plattform sammeln, wie z.B. booking.com oder hotels.com. Bei der Buchung ist es wichtig, Zimmer mit kostenloser Stornierungsoption für Flexibilität während Ihrer Reise auszuwählen.
  Ich möchte Hilfe bei der Buchung
  Wenn Sie uns um Hilfe bei der Zusammenstellung eines Reisevorschlags bitten, berechnen wir Ihnen als Kunden keine Gebühr. Die Zahlung für das Hotel erfolgt direkt beim Aufenthalt im Hotel, und wir verarbeiten niemals Ihre Zahlungsinformationen. Wir reservieren die Hotels für Sie, und die Buchung der Fahrräder erfolgt auf die gleiche Weise wie oben beschrieben.
  Pauschalreise und Reisegarantie
  Wir helfen Ihnen dabei, passende Hotels entlang Ihrer gewünschten Radroute zu finden. Da wir jedoch keine Zahlungen für Fahrräder abwickeln, bieten wir keine Pauschalreisen oder Reisegarantien an. Die Buchung für Fahrräder erfolgt über unsere Partner innerhalb von 48 Stunden, jedoch mindestens 24 Stunden nach der Hotelreservierung.`,

  "pickup_fee" : "Liefergebühr", 
  "pickup_fee_info" : "Wenn Sie Ihre Fahrräder an einem anderen Ort als Helsingborg abholen oder abgeben möchten, fällt eine Gebühr von 400 SEK pro Fahrrad an.",
  
} 
