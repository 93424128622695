import { useState, useEffect } from 'react';
import { db } from '../../api/firebase';
import { doc, setDoc } from 'firebase/firestore';
import './ResponseView.css';
import Map from '../../views/Main/Map';
import { renderToStaticMarkup } from 'react-dom/server';
import emailjs from 'emailjs-com';
import { SyncLoader } from 'react-spinners';
import BackButton from '../../components/buttons/BackButton';
import RouteView from './Result/RouteView';
import HotelsView from './Result/HotelsView';
import { Tr } from '../../../translations/translation';
import { validateEmailField, validateNameField } from '../../components/utils/Validators';
import ErrorPopup from '../../components/Popups/ErrorPopup';
import { showMap } from '../../components/TestHelper';
import PopupInfo from '../../components/Popups/PopupInfo';

const ResponseView = ({ hasOrdered, onBack, bookingData, setBookingData }) => {
  
  const [email, setEmail] = useState(bookingData.email ? bookingData.email : "");
  const [name, setName] = useState(bookingData.name ? bookingData.name : "");
  const [hotelsInfo, setHotelsInfo] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [emailString, setEmailString] = useState("");
  const [menuType, setMenuType] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const BIKE_RESERVATION = 1;
  const OTHER_RESERVATION = 2;

  const MENU_TYPE_HOTELS = 0;

  useEffect(() => emailjs.init(process.env.REACT_APP_EMAILJS_KEY), []);

  const handleEmail = (e) => {
    setEmail(e.target.value);
  }

  const handleName = (e) => {
    setName(e.target.value);
  }

  const handleBackPressed = () => {
    onBack(() => (OTHER_RESERVATION));
  }

  const handleSaveAndSendBikePlan = () => {
    if (!validateFields()) {
      return;
    }

    const newBookingData = {
      ...bookingData,
      startDate: bookingData.startDate,
      endDate: bookingData.endDate,
      adults: bookingData.adults,
      rooms: bookingData.rooms,
      from: bookingData.from,
      to: bookingData.to,
      bikesData: bookingData.bikesData,
      bikesCost: bookingData.bikesCost,
      hotelsInfo: hotelsInfo,
      comment: bookingData.comment,
      name: bookingData.name ? bookingData.name : name,
      email: bookingData.email ? bookingData.email : email
    };
    setBookingData(newBookingData);

    const toStore = {
      bookingData: JSON.stringify(newBookingData),
      email: newBookingData.email,
      name: newBookingData.name
    }
    saveAndSendBikePlan(toStore);
  }

  const validateFields = () => {
    if (!validateEmailField({email, setErrorMessage})
    || !validateNameField({name, setErrorMessage})) {
      return false;
    }

    if (!validateHotels()) {
      return false;
    }
    return true;
  }

  const validateHotels = () => {
    if (bookingData.hasBaggageTransport && hotelsInfo.length !== bookingData.towns.length) {
      setErrorMessage(Tr("error_baggage_transport"));
      return false;
    }
    return true;
  }

  const saveAndSendBikePlan = async (toStore) => {
    setIsLoading(true);
    setDoc(doc(db, "bikePlans", toStore.email), toStore)
      .then(() => {
        console.log("Document successfully written!");
      })
      .then(() => {
        sendEmailToCustomer(toStore);
      })
      .catch((error) => {
        setEmailString(Tr("result_error_database") + ": ", error);
      })
      .finally(() => {
        setIsLoading(false);
        setEmailString(Tr("result_email_sent"));
      })
  }

  const travelPlanEmailTemplate = () => {
    return (
      <div>
        <h1>{Tr("email_travelplan_title")}</h1>
        <p>{Tr("email_travelplan_text")}</p>
        <a href={`https://bikehotelplanner.com/show-bikeplan?email=${bookingData.email}`}>{Tr("email_travelplan_link_title")}</a>
        <p>{Tr("email_greeting")}</p>
      </div>
    );
  }

  const sendEmailToCustomer = async (toStore) => {
    const htmlContent = renderToStaticMarkup(travelPlanEmailTemplate());
    
    const templateParams = {
      to_email: bookingData.email ? bookingData.email : toStore.email,
      to_name: bookingData.name ? bookingData.name : toStore.email,
      from_name: "Bike Hotel Planner",
      from_email: "booking@bikehotelplanner.com",
      message: htmlContent
    };    

    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, templateParams)
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setEmailString(Tr("result_email_sent"));
    }, (err) => {
      console.log(err);
      setEmailString(Tr("something_wrong") + ": " + err.message);
    })
    .finally(() => {
      setIsLoading(false);
    }); 
  }

  const loadingAndButtonContainer = () => {
    return( 
    <div className="">
      <button className='submit-button' onClick={handleSaveAndSendBikePlan}>{Tr("result_send_button")}</button>
      {isLoading && <SyncLoader color="#36d7b7" size={10} />}
      {emailString.length > 0 && <p>{emailString}</p>}
    </div>
    )
  }

  const inputContainer = () => {
    return (
      <div>
        <div className='above-input-text'>
          {Tr("result_input_text")}:
        </div>
        <div className=''>
          {Tr("name")}:
        </div>
        <input className='text-field text-field-email' placeholder={Tr("name")} type="text" value={name} onChange={handleName} />
        <div className=''>
          {Tr("email")}:
        </div>
        <input className='text-field text-field-email' placeholder={Tr("email")} type="email" value={email} onChange={handleEmail} />
      </div>
    )
  }

  const handleHotelSaved = (hotelInfo) => {
    if (!hotelsInfo.some(hotel => hotel.coordinates.lat === hotelInfo.coordinates.lat && hotel.coordinates.lng === hotelInfo.coordinates.lng)) {
      setHotelsInfo([...hotelsInfo, hotelInfo]);
    }
  }

  const handleHotelDeleted = (hotelInfo) => {
    setHotelsInfo(hotelsInfo.filter(hotel => hotel.town !== hotelInfo.town));
  }
  
  const displayItinerary = () => {
    return (
      <div>
        <div className='top-container'>
          <h1 className='text-big font-bold mt-2'>{Tr("result_title")}</h1>
          <label className='text-sm'>{Tr("result_text")}</label>
        </div>
        <br />
        {showMap && <Map hotelsInfo={hotelsInfo} from={bookingData.from} to={bookingData.to} />}
        <br />
        <br />
        <div className='menu-container'>
          <div className="menu-switcher flex justify-center space-x-20 mb-3">
            <button onClick={() => setMenuType(0)}>
              {Tr("hotel")}
              <div className={menuType === MENU_TYPE_HOTELS ? "indicator hotel-selected" : "indicator hidden"}></div>
            </button>
            <button onClick={() => setMenuType(1)}>
              
              {Tr("route_description")}
              <div className={menuType === MENU_TYPE_HOTELS ? "indicator hidden" : "indicator route-selected"}></div>
            </button>
            {/* <div className='indicator-container'>
            <div className={menuType === MENU_TYPE_HOTELS ? "indicator left-selected" : "indicator right-selected"}></div>
          </div> */}
          </div>
          
        </div>
        { menuType === MENU_TYPE_HOTELS ? <HotelsView bookingData={bookingData} onHotelSaved={handleHotelSaved} onHotelDeleted={handleHotelDeleted} /> : <RouteView bookingData={bookingData} /> }
        <br />
      </div>
    )
  }

  const bikeOrderContainer = () => {
    return (
      <div className='mt-6'>
        <label className='mr-5'>{Tr("result_bike_order_text")}</label><br/>
        <button className='submit-button' onClick={() => onBack(BIKE_RESERVATION)}>{Tr("result_bike_order_button")}</button>
      </div>
    )
  }

  return (
    <div>
    {errorMessage.length > 0 && <ErrorPopup 
        errorTitle={Tr("something_wrong")}
        errorMessage={errorMessage} 
        setErrorMessage={setErrorMessage}
      />}
      {hasOrdered ? <BackButton onBackPressed={handleBackPressed} /> : null}
      <div className='mb-4'>
          <PopupInfo type="book_hotel_info" contentStyle={{ width: "80%" }} />
      </div>
      {displayItinerary()}
      <br />
      {!bookingData.email && inputContainer()}
      {loadingAndButtonContainer()}
      {!bookingData.bikesCost ? bikeOrderContainer() : null}
      <br />
    </div>
  );
}

export default ResponseView;