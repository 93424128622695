import PopupInfo from '../../components/Popups/PopupInfo.jsx';
import React from 'react';
import { useState, useEffect } from 'react';
import './Menus.css';
import towns from '../../data/towns.jsx';
import { Tr } from '../../../translations/translation.tsx';
import townsInRange from '../../components/Popups/helpers/townsInRange.jsx';
import routeDataForTown from '../../components/Popups/helpers/routeDataForTown.jsx';
import { formatDateShortForDisplay } from '../../components/Date/DateHandler.jsx';

// datepicker
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-date-picker'

const MenusView = ({ setMenusData }) => {
  //const [distance, setDistance] = useState(20);
  const [numberOfNights, setNumberOfNights] = useState('');
  const [selectFrom, setSelectedFrom] = useState('Helsingborg');
  const [selectTo, setSelectedTo] = useState('Göteborg');
  const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage') || 'sv');
  const [dateFrom, setDateFrom] = useState(new Date());
  //const [dateTo, setDateTo] = useState(new Date());
  const [numberOfAdults, setNumberOfAdults] = useState('2');
  const [numberOfChildren, setNumberOfChildren] = useState('0');
  const [numberOfRooms, setNumberOfRooms] = useState('1');

  useEffect(() => {
    const newNumberOfNights = calculateNumberOfNights();
    setNumberOfNights(newNumberOfNights);
    setMenusData({
      startDate: dateFrom,
      endDate: calculateDateTo(),
      adults: numberOfAdults,
      rooms: numberOfRooms,
      from: selectFrom,
      to: selectTo
    });
  }, [selectTo, selectFrom, dateFrom, numberOfAdults, numberOfRooms, numberOfNights]);


  const calculateNumberOfNights = () => {
    const tir = townsInRange(selectFrom, selectTo);
    return tir.length-1
  };

  const calculateDateTo = () => {
    const toDate = new Date(dateFrom)
    toDate.setDate(toDate.getDate() + numberOfNights)
    return toDate
  }

  const handleSelectFrom = (event) => {
    setSelectedFrom(event.target.value) 
  };

  const handleSelectTo = (event) => {
    setSelectedTo(event.target.value)
  };

  const handleSelectLanguage = (language) => {
    setLanguage(language);
    localStorage.setItem('selectedLanguage', language);
    window.location.reload();
  };

  const flagContainer = () => {
    return(
      <div className='flag-container'>
        <button className= {language === 'en' ? 'flag-button-selected' : 'flag-button'}  onClick={() => handleSelectLanguage('en')}>🇬🇧</button>
        <button className={language === 'sv' ? 'flag-button-selected' : 'flag-button'} onClick={() => handleSelectLanguage('sv')}>🇸🇪</button>
        <button className={language === 'de' ? 'flag-button-selected' : 'flag-button'} onClick={() => handleSelectLanguage('de')}>🇩🇪</button>
      </div>
    );
  }

  const handleNumberOfAdults = (event) => {
    setNumberOfAdults(event.target.value);
  }

  const handleNumberOfChildren = (event) => {
    setNumberOfChildren(event.target.value);
  }

  const handleNumberOfRooms = (event) => {
    setNumberOfRooms(event.target.value);
  }

  const fromToContainer = () => {
    return(
      <div className='from-to-container mb-4'>
        <div className='from-container'>
          {Tr('from')}: {" "}<br />
          <select className='drop-down' value={selectFrom} onChange={handleSelectFrom}>
            {towns.slice(0, towns.findIndex(t => t.name === selectTo)).map((town, index) => (
              <option key={index} value={town.name}>{town.name}</option>
            ))}
          </select>
          <br />
          {Tr('start_date')}: {" "}
          <DatePicker 
            onChange={setDateFrom} 
            value={dateFrom} 
            minDate={new Date()}
            format='yyyy-MM-dd'
            className="datepicker"
            />
        </div>
        <br />

        <div className='to-container'>
        {Tr('to')}: {" "} <br />
          <select className='drop-down' value={selectTo} onChange={handleSelectTo}>
         {towns.slice(towns.findIndex(t => t.name === selectFrom) + 1).map((town, index) => (
              <option key={index} value={town.name}>{town.name}</option>
            ))}
          </select>
          {/* Distans per dag: {" "} <br />
      <select className='drop-down' value={distance} onChange={handleSelectDistance}>
        <option value="20km">20km</option>
        <option value="30km">30km</option>
        <option value="40km">40km</option>
        <option value="50km">50km</option>
        <option value="70km">70km</option>
        <option value="100km">100km</option>
      </select> */}
          <br />
        </div>
      </div>
    )
  }

  const allTowns = () => {
    const tir = townsInRange(selectFrom, selectTo);
    var prevDistance = "0km";
    return (
      <div>
        {tir.map((town, index) => {
          const currentLoopDate = new Date(dateFrom);
          currentLoopDate.setDate(currentLoopDate.getDate() + index);
          const displayDate = formatDateShortForDisplay(currentLoopDate);
          const townDescription = `${Tr("day")} ${index + 1} - ${displayDate} - ${prevDistance}: \n ${town.name}`;
          prevDistance = routeDataForTown(town).distance;
          return (<div key={index}>{townDescription}</div>);
        })}
      </div>
    );
  }

  const allDistancesInOneText = () => {

    const tir = townsInRange(selectFrom, selectTo);
    const allDistances = tir.map((town, index) => {
      if (index !== tir.length - 1) {
        return routeDataForTown(town).distance;
      }
      return null;
    })

    return allDistances.filter(distance => distance !== null).join('\n');
  }

  const totalDistance = () => {
    const allDistances = allDistancesInOneText().split('\n');
    return allDistances.reduce((acc, distance) => acc + parseInt(distance), 0);
  }

  const preview = () => {
      return (
        <div>
          <strong> {Tr("overview")}: </strong>
            <div className="flex">
              <div className='mr-10'>
                {allTowns()}
              </div>
              {/* <div className='distant-text mt-3'>
                {addLineBreaks(allDistancesInOneText())}
              </div>*/}
            </div> 
        </div>
      );
  }

  return (
    <div className="top-container">
      <div className="flex flex-right">
        {flagContainer()}
        <PopupInfo type="trip_info" contentStyle={{ width: "80%" }} />
      </div>
      <br />

      {fromToContainer()}
      {preview()}
      <br />

      <div className="flex flex-row">
        <div className="mr-6">
          <strong>{Tr("no_nights")}: </strong>{numberOfNights} <br />
        </div>

        <div className="mr-6">
          <strong>{Tr("total_distance")}: </strong>{totalDistance()} km
        </div>
      </div>
      <br />

      <div className='flex flex-row '>
      <div className="mr-6">
        {Tr("no_adults")}:{" "}
        <input
          className="text-field text-field-number"
          type="number"
          value={numberOfAdults}
          onChange={handleNumberOfAdults}
        />
      </div>
      <div className="roomsContainer">
        {Tr("no_rooms")}:{" "}
        <input
          className="text-field text-field-number"
          type="number"
          value={numberOfRooms}
          onChange={handleNumberOfRooms}
        />
</div>
      {/* <div className='mb-2'>
        {Tr('no_children')}: {" "}
          <input className='text-field text-field-number' type="number" value={numberOfChildren} onChange={handleNumberOfChildren} />
        </div> */}
      

      <br />

      </div>
    </div>
  );
}

export default MenusView;

// const query = () => {
//   return `1. From ${selectFrom} to ${selectTo}
//     2. ${numberOfNights} 
//     3. ${language}`;
// }





// const handleInterests = (event) => {
//   setInterests(event.target.value);
//   queryToSend(query)
// };

// const handleRouteDescription = (event) => {
//   setRouteDescription(event.target.value);
//   queryToSend(query)
// };


// const interests = () => {
//     return(
//       <div>
//         Intressen (vad vill du se på vägen): {" "}<br />
//         <input className='dropDown' type="text" value={interests} onChange={handleInterests} />
//         <br />
//       </div>
//     );
// }

{/* Ruttbeskrivning (vilka platser du gärna vill besöka på vägen): {" "} <br />
      <input className='dropDown' type="text" value={routeDescription} onChange={handleRouteDescription} /> */}