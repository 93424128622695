import React from 'react';
import infoIconUrl from '../../../img/svg/info_logo.svg';
import './InfoIcon.css';

interface InfoIconProps {
  size?: number;
  color?: string;
}

const InfoIcon: React.FC<InfoIconProps> = ({ size = 26 }) => {
  return (
    <img 
      className='inline-img' 
      src={infoIconUrl} 
      alt="Info Icon" 
      width={size} 
      height={size} 
    />
  );
};

export default InfoIcon;
