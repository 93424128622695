import { Tr } from '../../../translations/translation';

const validateEmailField = ({email, setErrorMessage}) => {
  console.log(email)
  if (email.length === 0) {
    setErrorMessage(Tr("email_error_nothing"));
    return false;
  }

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  if (!emailRegex.test(email)) {
    setErrorMessage(Tr("email_error_invalid"));
    return false;
  }
  return true;
}

const validatePhoneField = ({phone, setErrorMessage}) => {
  if (phone.length === 0) {
    setErrorMessage(Tr("phone_error_nothing"));
    return false;
  }
  const phoneRegex = /^\d{10}$/;
  if (!phoneRegex.test(phone)) {
    setErrorMessage(Tr("phone_error_invalid"));
    return false;
  }
  return true;
}

const validateNameField = ({name, setErrorMessage}) => {
  if (name.length === 0) {
    setErrorMessage(Tr("name_error_nothing"));
    return false;
  }
  return true;
}

export { validateEmailField, validatePhoneField, validateNameField, };