import { APIProvider } from "@vis.gl/react-google-maps";
import PlacesTextField from "../../../components/PlacesTextField";
import {
  formatDateForDisplay,
  formatDateForAPI,
} from "../../../components/Date/DateHandler";
import routeDataForTown from "../../../components/Popups/helpers/routeDataForTown";
import { Tr } from "../../../../translations/translation";
import towns from "../../../data/towns";

const HotelsView = ({ bookingData, onHotelSaved, onHotelDeleted }) => {
  const createLinkFromTown = ({ routeTo, currentDayDate, nextDay }) => {
    const formattedCurrentDay = formatDateForAPI(currentDayDate);
    const formattedNextDay = formatDateForAPI(nextDay);
    const baseHotelURL = "https://sv.hotels.com/Hotel-Search?";
    return `${baseHotelURL}destination=${routeTo}%2C%20Sverige&flexibility=0_DAY&startDate=${formattedCurrentDay}
      &endDate=${formattedNextDay}
      &adults=${bookingData.adults}
      &rooms=${bookingData.rooms}
      &theme=&userIntent=&semdtl=&useRewards=false
      &sort=RECOMMENDED&afflid=1101lyCvZbPg`;
    // &poi=&latLong=${town.coordinates.lat}%2C${town.coordinates.lng}
    // &mapBounds=${town.mapBounds.northEast.lat}%2C${town.mapBounds.northEast.lng}
    // &mapBounds=${town.mapBounds.southWest.lat}%2C${town.mapBounds.southWest.lng}
    //TODO Add locale for hotels.com
  };

  const dailyView = (
    {index: index,
      routeName: routeName,
      overnightTown: overnightTown,
      startDate: startDate,
      routeDistance: routeDistance
  }) => {
    const townInfo = towns.find(town => town.name === overnightTown);
    const currentDayDate = new Date(startDate);
    currentDayDate.setDate(currentDayDate.getDate() + index);
    const nextDay = new Date(currentDayDate);
    nextDay.setDate(nextDay.getDate() + 1);
    const overNightDate = formatDateForDisplay(currentDayDate) + " - " + formatDateForDisplay(nextDay);
    return (
      <div>
        <h2 className="text-xl mt-3 mb-1">
          Dag {index}: {routeName}
        </h2>
        <div className="route-text-container">
          <strong>{Tr("hotel_town")}:</strong> {overnightTown}
          <br />
          <strong>{Tr("hotel_date")}:</strong> {overNightDate}
          <br />
          <strong>{Tr("bike_distance")}:</strong> {routeDistance}
          <br />
          <strong>{Tr("hotel_link")}: </strong>
          <a
            className="read-more-button"
            href={createLinkFromTown({
              routeTo: overnightTown,
              currentDayDate,
              nextDay,
              townInfo,
            })}
            target="_blank"
            rel="noopener noreferrer"
          >
            {Tr("reserve_hotel_for")} {overnightTown}
          </a>
          <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
            <PlacesTextField
              hotelInfoIn={bookingData.hotelsInfo ? bookingData.hotelsInfo.find(hotel => hotel.town === overnightTown) : null}
              townName={townInfo.name}
              coordinates={townInfo.coordinates}
              onHotelSaved={onHotelSaved}
              onHotelDeleted={onHotelDeleted}
            />
          </APIProvider>
          <br />
          <br />
        </div>
      </div>
    );
  };

  const dailyIntinerary = (index, town, startDate) => {
    if (index == bookingData.towns.length - 1) { //x towns but only x-1 overnights, return to not display extra night
      return;
    }
    const route = routeDataForTown(town);
    if (!route) {
      return;
    }
    return dailyView({
      index: index + 2,
      routeName: route.name,
      overnightTown: route.to,
      startDate: startDate,
      routeDistance: route.distance
    });
  };

  const initialDayView = () => {
    return dailyView({ 
      index: 1,
      routeName: Tr("arrival") + " " + bookingData.towns[0].name,
      overnightTown: bookingData.towns[0].name,
      startDate: bookingData.startDate,
      routeDistance: "0km"
    })
  }

  return (
    <div>
      {initialDayView()}
      {bookingData.towns.length > 0 &&
        bookingData.towns.map((town, index) => (
          <div key={index}>
            {dailyIntinerary(index, town, bookingData.startDate)}
          </div>
        ))}
    </div>
  );
};

export default HotelsView;
