import { swedish } from "./sv";
import { german } from "./de";
import { english } from "./en";

import i18next from 'i18next';

i18next.init({
  lng: localStorage.getItem('selectedLanguage') || 'sv', // get the default language from local storage or set to Swedish
  resources: {
    sv: {
      translation: swedish
    },
    de: {
      translation: german
    },
    en: {
      translation: english
    }
  }
});

export const Tr = (key: string) => {
  return i18next.t(key);
};

export const TrWithParams = (key: string, value1: string, value2: string, value3: string) => {
  const params = {
    value1: value1,
    value2: value2,
    value3: value3
  }
  return i18next.t(key, { params });
};

