export const swedish = {

  //common
  "from": "Från",
  "to": "Till",
  "date": "Datum",
  "time": "Tid",
  "h1_title" : "Din perfekta cykelresa på Kattegattleden",
  "subtitle" : "Njut av din cykelsemester och spara pengar",
  "helmet_title" : "Kattegattleden | Planera din cykelresa",
  "start_date": "Startdatum",
  "no_adults": "Antal vuxna",
  "no_children": "Antal barn",
  "no_rooms": "Antal rum",
  "no_nights" : "Hotellnätter",
  "calculate_route" : "Beräkna resan",
  "back" : "Tillbaka",
  "close" : "Stäng",
  "reserve_bikes" : "Reservera cyklar",
  "comment" : "Kommentar",
  "continue" : "Fortsätt",
  "skip" : "Hoppa över",
  "something_wrong" : "Något gick fel",
  "total_bikes" : "Totalt cyklar",
  "bike_size" : "Cykelstorlek",
  "email" : "Epost",
  "phone" : "Telefon",
  "name" : "Namn",
  "reservation_info" : "Reservationsuppgifter",
  "bike_distance" : "Cykelavstånd",
  "route_info" : "Info",
  "reservation_email_sent" : "Din reservation har skickats",
  "currency" : "kr",
  "reserve_hotel_for" : "Reservera hotell för",
  "hotel_in" : "Sök hotell i ",
  "bike" : "Cykel",
  "baggage" : "Baggage",
  "day": "Dag",
  "overview" : "Översikt",
  "total_distance" : "Totalt",
  "height" : "Längd (cm)",
  "route_description" : "Ruttbeskrivning",
  "your_booking_for" : "Du bokar för {{params.value1}} personer för datumen {{params.value2}} - {{params.value3}}",

  "accessories" : "Tillbehör",
  "addons" : "Tillägg",
  "total_addons" : "Totalt tillbehör & tillägg",
  "total" : "Totalt",

  //result view
  "result_title" : "Din cykelresa",
  "result_text" : "Följ länkarna nedan och boka ditt hotell för varje destination enligt listan. Tänk på att alltid boka med gratis om- och avbokning ifall det blir svårt att boka de hotell du vill på respektive ort.",
  "result_input_text" : "Fyll i dina uppgifter nedan för att få din reseplan på e-post",
  "result_send_button" : "Skicka resultat till e-post",
  "result_bike_order_text" : "Du har inte beställt några cyklar",
  "result_bike_order_button" : "Boka cyklar",
  
  "hotel_town" : "Stad för övernattning",
  "hotel_date" : "Datum för övernattning",
  "hotel_link" : "Länk till hotell",
  "hotel_add_hotel" : "Lägg till ditt bokade hotell här:",

  // transport & accessories
  "transport_title" : "Reservera baggagetransport och tillbehör",
  "total_bike_cost" : "Totalt cykel",

  //textfield
  "save_hotel_on_map" : "Spara hotell",

  //error messages
  "email_error_nothing" : "Var snäll och fyll i mail",
  "email_error_invalid" : "Var snäll och fyll i en giltig epost",
  "phone_error_nothing" : "Var snäll och fyll i telefonnummer",
  "phone_error_invalid" : "Var snäll och fyll i ett giltigt telefonnummer",
  "name_error_nothing" : "Var snäll och fyll i namn",
  "result_error_database" : "Det blev något fel när vi skulle lägga till din användare i databasen",
  "result_email_sent" : "Din reseplan har skickats",

  // email reservation
  // should always be in swedish to travelshop but other language when contacting other bikeshops
  "email_resevation_bikeshop_title" : "Kundens bokning",
  "email_resevation_bikeshop_infomation" : "Här kommer en reservation från Bike Hotel Planner",
  "email_resevation_bikeshop_contact_title" : "Kundens uppgifter",
  "email_resevation_bikeshop_reservation_title" : "Bokning och detaljer",

  // email customer reservation
  "email_customer_resevation_title" : "Din reservationsbekräftelse",
  "email_customer_resevation_infomation" : `- Bokningen har skickats iväg till vår partner som har cyklarna på plats.
  - De kommer boka in dem till er samt skicka en säker länk för betalning.
  - Reservationen är bindande först efter erlagd betalning.
  - Om du bokar minst 7 dagar innan avresa garanterar vi tillgången på cyklar.
  - Säkerställ först bokningen av boende innan betalning av cyklarna.
  - Din bokning är inte helt genomförd förrän vår cykelleverantör har bekräftat beställningen. Detta kan ta upp till 48h`,
  "email_customer_resevation_contact_title" : "Dina uppgifter",
  "email_customer_resevation_reservation_title" : "Din reservation",

  // email travel plan
  "email_travelplan_title" : "Din reseplan",
  "email_travelplan_link_title" : "Klicka här för att se din reseplan",
  "email_travelplan_text" : "Din reseplan har skapats och du kan se den när du följer länken:",
  "email_greeting" : "Hoppas du får en trevlig cykelresa",

  // info popup text
  "reservation_info_title": "Så fungerar reservationen",
  "reservation_info_text": `- När du reserverar cyklar och tillbehör skickas bokningen iväg till vår partner som har cyklarna på plats.
  - De kommer boka in dem till er samt skicka en säker länk för betalning.
  - Reservationen är bindande först efter erlagd betalning.
  - Om du bokar minst 7 dagar innan avresa garanterar vi tillgången på cyklar.
  - Säkerställ först bokningen av boende innan betalning av cyklarna.
  - När reservationen är genomförd får du en bekräftelse på mail. Din bokning är inte helt genomförd förrän vår cykelleverantör har bekräftat beställningen. Detta kan ta upp till 48h`,

  "planner_info_title" : "Så fungerar det",
  "planner_info_text" : `Med vår cykelreseplanerare gör vi det lättare för dig att: 
  Boka hotell på rätt datum och ort längs leden
  Spara pengar genom att boka själv med vår tjänst
  Du får en praktisk översikt och gps fil med led och hotell
  Välj varje individuellt hotell och prisnivå
  Fyll i datum, antal vuxna, antal rum samt avresedatum och beräkna rutten. 
  I nästa steg kan du fylla i din förfrågan om att reservera cyklar, tillbehör och bagagetransporter mellan dina hotell. Du kan också hoppa över det steget och ta det senare. 
  När resan presenteras kan du gå vidare och boka hotellen på hotels.com eller booking.`,

  "places_info_textfield_title" : "Lägg till bokade hotell",
  "places_info_textfield_text" : `Om du har valt baggage transport måste vi få tillgång till vilka hotell ni bor på för att kunna hämta och lämna baggaget. Du skräddarsyr också din karta med utmarkerat hotell, så du lättare hittar dit.`,

  //info popup booking reservation
  "popup_booking_reservation_text" : `Förfrågan skickas vidare till leverantören som återkommer inom 48h med en bekräftelse av bokningen och en säker betallänk. 
  Genom betalningen bekräftar du beställningen och följer avtalsvillkoren som skickas med.`,
  "popup_booking_button" : "Bekräfta & reservera",

  // bike cart items
  "touring_bike_name" : "Touring, unisex",
  "touring_bike_info" :  `En touringcykel, är en hybridcykel med bekvämare sittställning där styret och sadeln kommer i samma höjd eller tom styret över sadeln. Touringcyklarna är designade för detta ändamål och har också goda packningsmöjligheter. 
  Om du funderar över storleken och ligger på gränsen mellan två storlekar är den mindre oftast att föredra för att en mindre ram är lättare att hantera och styret är närmre sadeln. Då kan du också få sadeln på rätt höjd för dig.`,

  "touring_bike_electric_name" : "El Touringcykel",
  "touring_bike_electric_info" : "De 7-växlade elcyklarna är att betrakta som en vanlig fotbromsad cykel med interna växlar i navet. Motorn sitter i framhjulet och ger en bra kraft framåt. Sittpositionen är bekväm och du kommer helt klart att känna igen dig från en vanlig cykel.",

  "electric_city_name": "Elcykel 7-växlad",
  "electric_city_info": "De 7-växlade elcyklarna är att betrakta som en vanlig fotbromsad cykel med interna växlar i navet. Motorn sitter i framhjulet och ger en bra kraft framåt. Sittpositionen är bekväm och du kommer helt klart att känna igen dig från en vanlig cykel.",

"standard_name" : "City Standardcykel",
"standard_info" : "", 

  // accessories cart items
  "bag" : "Väska",
  "bag_info" :  "En sidoväska eller pannier som den kallas är en vattentät väska på 20-25 liter som du enkelt hänger på pakethållaren. Om du inte bokar bagagetransport men bor på hotell eller liknande behöver du sannolikt två väskor per person samt en väska ovanpå pakethållaren.",
  "helmet" : "Hjälm",
  "helmet_info" : "En hjälm",
  "child_seat" : "Barnstol",
  "child_seat_info" : `Barnstolen kan monteras på nästan alla cyklar och passar barn som kan sitta själva och hålla huvudet upprätt upp till max 25kg.
  Meddela som extrainformation i bokningen vilken cykel som skall ha barnstolen.`,
  "baggage_transport" : "Baggagetransport",
  "baggage_transport_info" : `När du har bokat baggagetransport kommer vi att förse dig med en baggagetag för din väska som du fyller i dina uppgifter på. Vi skickar dig en lista där du kan fylla i var du bor under resan och när du vet var du skall bo meddelar du oss där. Baggagetaggarna får du antingen i receptionen första natten eller när du hämtar ut hyrcyklarna. Från 9:30 behver baggaget stå på anvisad plats i receptionen. Vi levererar väskorna till din nästa vernattning under eftermiddagen. Tänk på maxvikten på 25kg för vår personals hälsa.`,
  "baggage_transport_pricetype" : "kr/person",
  "ts_insurance" : "TS skydd",
  "ts_insurance_info" : "Med TS skydd får ni hjälp längs vägen och om något går snder är det upp till 2000kr. Ni får tillgång till rep-kit och ett journummer. Vid behov byter vi cykel dock kan vi inte garantera att det blir samma typ.",
  "ts_insurance_pricetype" : "kr hela perioden",

  // route info
  "route_1_info" : `När du lämnat Helsingborg City och efter en kort uppförsbacke kommer du till Sofiero slott och slottsträdgård, väl värt ett stopp speciellt om du är trädgårdsintresserad. När du fortsätter passerar du små pittoreska samhällen och småbåtshamnar som Domsten och Viken innan du kommer fram till Höganäs. Ett bra ställe för paus, mat och dryck är Höganäs hamn. Leden går vidare över vackra och natursköna Kullahalvön. Längst ut ligger naturreservatet Kullaberg med Kullens fyr, en avstickare du inte ångrar. På Kullahalvön ligger ett av Sveriges nya vindistrikt med flera vingårdar som du kan besöka. Här finns också flera restauranger, matställen och caféer. Missa inte att ta en fika hos Flickorna Lundgren på Skäret. Innan du når Ängelholm cyklar du genom flera små mysiga kustsamhällen. Du cyklar över vackra Kullahalvön som är fylld med konst, gårdsbutiker och loppisar. Sträckan är en blandning av lågt böljande, öppna fält och hög, kuperad terräng med en fantastisk utsikt. Efter Höganäs passerar du små hamnar och badstränder som Strandbaden och Nyhamnsläge. Turen fortsätter över den vackra, dramatiska Kullahalvön. Strax efter Lerhamn ligger Krapperups borg med anor från 1500-talet. Längst ut på Kullahalvön ligger naturreservatet Kullaberg, och har du tid över för en avstickare är det väl värt ett besök. Kullaberg erbjuder en variation av aktiviteter, till exempel vandring och klättring på klipporna. Den högsta punkten – Håkull – mäter 187 meter över havet. När du åter når havet passeras samhällena Arild, Skäret, Jonstorp och Norra Häljaröd och på sträckan finns flera trevliga caféer.  På väg in mot Ängelholm ligger Kronoskogen. I dess södra del, som kallas Sibirien, finns ett spännande ufo-monument.`,
  "surface_1" : "90% asfalt, 10% grus",

  "route_2_info" : "Xxxxxxx",
  "surface_2" : "76% asfalt, 24% grus",
  
  "route_3_info" : "Denna del av Kattegattleden är den mest kuperade sträckningen. Njut av den vackra utsikten över havet och Bjärehalvöns variationsrika jordbrukslandskap.Kattegattleden passerar centrala Ängelholm alldeles intill tåg- och busstationen och lotsar dig vidare ner till den vackra stadsdelen Havsbaden. Här möts du av skog, orörd natur och en härligt bred och lång sandstrand. Stanna till vid strandkiosken och provsmaka Engelholms Glass, stadens eget stolta glassmärke. Färden fortsätter längs kusten mot Skälderviken, genom småbyar och fiskelägen. På vägen korsar du Rönne å – ett perfekt stopp för kanotpaddling eller kanske en avkopplande paddeltur på SUP-bräda. Du passerar Skepparkroken och Vejbystrand innan du i Killebäckstorp viker av mot pittoreska Torekov. Här finns caféer, restauranger och och du kan även ta båten över till Hallands Väderö. Från Torekov går turen vidare, in i själva hjärtat av Bjäre. Här väntar ett småskaligt jordbrukslandskap, typiskt för Bjärehalvön, format och baserat på boskapsskötsel sedan förhistorisk tid.  Du möter havet igen vid Norrvikens Trädgårdar och följer leden vidare mot Båstad hamn.",
  "surface_3" : "76% asfalt, 24% grus",
  
  "route_4_info" : "Denna delen av leden tar dig längs Sveriges längsta sandstrand och genom grönskande naturreservat. Från Båstad hamn följer du havet tills du når tallskogen i Hemmeslöv och passerar gränsen mellan Halland och Skåne. Nu väntar en lättrampad sträcka längs Laholmsbuktens långa sandstrand. I familjära Skummeslövsstrand hittar du restauranger och caféer. Här är havet långgrunt och landskapet öppet. Några kilometer in i landet ligger vackra Skottorps slott och mysiga Laholm, Hallands äldsta stad. Beräkna att det tar cirka 20 minuter från leden till Laholm. Ett par kilometer norr om Skummeslövsstrand når du Mellbystrand, en badort med minnen från svunna tider. Här är dynerna högre och havet vildare. Du passerar glassbutiker, minigolfbanor och uteserveringar. Norr om Mellbystrand väntar en sträcka längs med sanddyner, genom strandskog och naturreservat, förbi Laxvik och ut på den öppna landsbygden söder om Halmstad. Innan du lämnar havet kan du stanna till vid Påarps klapperstensfält med 220 järnåldersgravar. Leden passerar sedan Östra stranden innan den når Halmstad slott.",
  "surface_4" : "75% asfalt, 25% grus",
  
  "route_5_info" : "Cyklingen fortsätter norrut mot en av Sveriges mest bermda och vackraste stränder,Tylsand. Om du inte vill ta ett bad på den av livräddare bevakade stranden, tycker jag att du ska beska hotell Tylsand. På hotellet finns Sveriges strsta fotokonstgalleri och ett ”museum” som visar en del av Gyllene Tider och Roxettes historia. Leden fljer kusten norrut och passerar Haverdal och Särdal. Stanna till på Steninge kuststation fr en fika i Plätteriet eller Bikebar. Du behver inte cykla så långt innan du kommer till Ugglarps Havsbad och Långasand, båda med fina bad och restauranger. Efter en kort tur inåt landet kommer du åter ut till kusten och Grimsholmen.Här är idealiskt fr ett stopp och en kort promenad ut vattnet och kanske njuta av en medhavd matsäck. Du cyklar vidare och är snart framme i Falkenberg och Skrea Strand med sin långa badbrygga.",
  "surface_5" : "94% asfalt, 6% grus",
  
  "route_6_info" : "Sträckan Falkenberg-Varberg är en lättcyklad del av Kattegattleden, bara 40 km lång och inga backar att tala om. Du passerar flera små samhällen och byar. Stanna till i Olofsbo för att äta och dricka på Kvarnens Pensionat och restaurang, cykla ut till Morups Tånges naturreservat, titta på den vackra fyren och skåda på fåglar. Cykla en liten bit till och du är i Glommen, ett gammalt fiskeläge. Leden passerar många små byar och du är snart i Morup. Här kan du gra ett besk hos Kronmakaren Britten Toftarp. Du cyklar vidare på en relativt nyöppnad cykelbana, passerar Björkängs camping och i Utterås hittar du Rit och Målerigården. Ät en våffla och beundra all konst, kanske köpa nåt och skicka direkt hem. Du närmar dig Träslövsläge, ett fiskeläge med flera matställen. Här måste du köpa och smaka på de underbara nykokta havskräftorna. Cykla vidare och du kommer till Apelviken, ett eldorado för vindsurfare. Leden går vidare till Strandpromenaden och när du passerat Varbergs fästning är du framme i Varbergs hamn med Kallbadhuset och Societetsparken.",
  "surface_6" : "97% asfalt, 3% grus",
  
  "route_7_info" : "Leden fortsätter längs kusten och går genom ängsmark, skogar och jordbrukslandskap. Den här sträckan är lätt kuperad men inga stora backar. Strax norr om Varberg ligger Naturum Getterön, ett måste stopp för den fågelintresserade. Du cyklar genom små samhällen och kommer snart till Bua, ett gammalt fiskeläge. I hamnen finns flera matställen som serverar fisk och skaldjur. Leden fortsätter förbi Ringhals kärnkraftverk och vidare genom vacker strandskog. Du fortsätter längs kusten och passerar Kärradal, Stråvalla och Frillesås, alla med utmärkta restauranger, caféer och fina badstränder. Stanna till i Åsa och varför inte ta en glass på Åsa Glasscafé. I Åsa fortsätter leden inåt landet och du cyklar på vindlande småvägar. Strax söder om Fjärås kan du avvika från leden och göra en avstickare till Tjolöholms slott, Sveriges enda slott i Tudorstil. Du cyklar vidare längs åkrar och hästgårdar. I Kungsbacka går leden genom staden som är en gammal handelsplats.",
  "surface_7" : "95% asfalt, 5% grus",
  
  "route_8_info" : "Från Kungsbacka centrum kan du endera följa Kattegattledens skyltning och cykla mot Onsala eller följa cykelbanan längs väg 158 till Vallda där du ansluter till Kattegattleden igen. Denna alternativa väg förkortar sträckan till Göteborg med ca 20 km. Om du väljer att följa ledsträckningen mot Onsala cyklar du längs Kungsbackafjorden. I Onsala kan du besöka Båt- och Sjöfartsmuseet och få lära känna ”Lasse i Gatan” , kungens kapare Lars Gathenhielm. Fortsätt till Gottskär och njut av en glass och promenad i den vackra hamnen. Du cyklar vidare över Onsalahalvön och fram till Särö. Från Särö cyklar du på gamla Säröbanans banvall som går längs havet. Du passerar många småsamhällen med restauranger, fikaställen och gott om badplatser. Norr om Billdal följer du kusten och har en vacker vy över skärgården. Cykla ut till Lilla Amundö, sträck på benen och njut av naturen. Närmare Göteborg passerar leden Klippans kulturreservat och Röda Stens Konsthall. Vid Järntorget viker du av in mot City och sträckan går utmed Kungsparken för att sluta på Drottningtorget vid Centralstationen.",
  "surface_8" : "98% asfalt, 2% grus",
  
  




     //NEW:     
    "book_hotel_info_title" : "Så här bokar du hotell",
    "book_hotel_info_text" : `Följ en av länkarna för varje destination längs din resa till den externa bokningssidan. Boka sedan ett hotell på varje destination. Tänk på att göra det avbokningsbart så att du inte plötsligt står utan en av etappernas övernattning. 

    När du har bokat hotellet växlar du tillbaka till vår sida och skriver in hotellet i listan och sparar hotellet. 
    
    När du sparat ett hotell kan du direkt se att det ligger uppe på kartan där du även ser rutten längs leden. 
    
    När alla hotellen är bokade kan du skicka listan till din mail så att du behåller den. 
    
    Du kan också gå tillbaka och boka cyklar, tillbehör och bagagetransporter om du inte redan gjort det.`,
    "hotel": "Hotell",
     "arrival" : "Ankomst",
     "height_cyclist" : "Längd på cyklist",
     

     // NOT translated
     "error_baggage_transport" : "När du har bokat bagagetransport behöver du lägga till varje bokat hotell i formuläret för at uppdatera kartan med alla destinationer. Informationen skickas vidare direkt till den lokala utföraren för att de skall kunna hämta och lämna ert bagage under turen. ",
     
     "height_error" : "Var snäll och fyll i längden på personerna som ska cykla, på alla cyklar.",
     "height_wrong_error" : "Det verkar som du har lagt in en ogiltig längd.",
     
     "privacy_policy_title" : "Tjänster och allmänna villkor",
      "privacy_policy_text" : `Bokning av cyklar och tillbehör
      Vad händer när jag skickar en bokningsförfrågan på cyklar och tillbehör? När du skickar in en bokningsförfrågan via vår sida, skickas den omedelbart vidare till leverantören. Inom 48 timmar kommer du att bli kontaktad med information om tillgänglighet, det slutliga priset och svar på eventuella ytterligare frågor du har. Om du skickar din förfrågan minst 7 dagar innan avresan, kommer leverantören att bekräfta din förfrågan. Du kan sedan välja att acceptera eller avböja bokningen genom en länk som du får från cykelleverantören.
      Blir det dyrare att boka via er än att boka direkt med arrangören? Nej, vår affärsmodell innebär att vi får provision när du bokar via våra länkar eller skickar in en förfrågan på cyklar. Det tillkommer inga extra avgifter för varken dig eller arrangören. Priset förblir detsamma som om du hade bokat direkt hos arrangören.
      Det har gått mer än 24 timmar sedan jag skickade min förfrågan och jag har inte fått något svar. Vad ska jag göra? Om det har gått mer än 48 timmar sedan du skickade din förfrågan och du ännu inte har fått svar, vänligen kontakta oss via e-post så hjälper vi dig vidare. Tänk också på att kolla din skräppostmapp för att säkerställa att svaret inte hamnat där.
      Hur gör jag om jag vill avbryta en bokning? För cyklar är bokningen endast bekräftad när betalning sker via den länk och bekräftelse du får från uthyrningsföretaget. Om du inte har betalat har du inte förbundit dig till något. Avbokningsregler varierar beroende på avresedatum och du kommer att få detaljerad information om reglerna från leverantören.
      Vilka tjänster erbjuder ni?
      Vi erbjuder en plattform där du som kund självständigt kan boka och betala för boende längs din cykelrutt. Vi hjälper också till med att skicka vidare bokningsförfrågningar till lokala arrangörer av hyrcyklar. Arrangören svarar på din förfrågan inom 24-48 timmar och betalning och villkor följer den lokala arrangörens policy.
      Hur fungerar hotellreservationen?
      Vi förenklar din process att boka boende längs din cykelrutt genom att samla förslag från olika anläggningar på en gemensam plattform, exempelvis booking.com eller hotels.com. När du bokar är det viktigt att välja hotellrum med gratis avbokning för flexibilitet under din resa.
      Jag vill få hjälp med bokningen
      Om du ber oss om hjälp med att ta fram ett reseförslag tar vi inte ut någon avgift från dig som kund. Betalning av hotellet görs direkt till hotellet under din vistelse, och vi hanterar aldrig dina betalningsuppgifter. Vi reserverar hotellen åt dig och bokningen av cyklar sker på samma sätt som tidigare beskrivet.
      Paketresa och resgaranti
      Vi hjälper dig att hitta lämpliga hotell längs din önskade cykelrutt. Vi tillhandahåller inte paketresor eller en resgaranti eftersom vi inte hanterar betalning för cyklar. Reservationen för cyklar görs hos våra samarbetspartners inom 48 timmar, men minst 24 timmar efter hotellreservationen.`,
     
      "pickup_fee" : "Upphämtningsavgift",
      "pickup_fee_info" : "Vill du hämta eller lämna din cykel på annat ställe än Helsinborg tillkommer en avgift på 400kr per cykel."
}