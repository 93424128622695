import { Tr } from "../../translations/translation";
const bikesItemData = [
  {
    image: "https://travelshop.se/_next/image?url=https%3A%2F%2Fcdn3.booqable.com%2Fuploads%2F9928383ce983527ba603efd8356a658c%2Fphoto%2Fphoto%2F20cea0e5-acdf-4da6-8749-c01f449932c3%2Flarge_upload.jpg&w=1920&q=100",
    name: Tr("standard_name"),
    gender: "unisex",
    price: 200,
    info: Tr("standard_info"),
    type: "bike",
    bikesInCart: [],
  },
  {
    image: "https://03.cdn37.se/zY/images/2.1701340/scott-hybridcykel-sub-comfort-10-unisex.jpeg",
    name: Tr("touring_bike_name"),
    gender: "unisex",
    price: 300,
    info: Tr("touring_bike_info"),
    type: "bike",
    bikesInCart: [],
  },
  {
    image: "https://travelshop.se/_next/image?url=https%3A%2F%2Fcdn3.booqable.com%2Fuploads%2F9928383ce983527ba603efd8356a658c%2Fphoto%2Fphoto%2F7c8cd33f-eadb-4410-8c28-f8e9ac907a10%2Flarge_1716887944-349315358898111-0008-8675%2Fupload.jpg&w=1920&q=100",
    name: Tr("touring_bike_electric_name"),
    gender: "unisex",
    price: 400,
    info: Tr("touring_bike_electric_info"),
    type: "bike",
    bikesInCart: [],
  },
  {
    image: "https://travelshop.se/_next/image?url=https%3A%2F%2Fcdn3.booqable.com%2Fuploads%2F9928383ce983527ba603efd8356a658c%2Fphoto%2Fphoto%2F27ddd63e-1bc5-4f61-8884-e4b9466fb0c7%2Flarge_1714553122-977078350777155-0001-2328%2Fupload.jpg&w=1920&q=100",
    name: Tr("electric_city_name"),
    gender: "unisex",
    price: 350,
    info: Tr("electric_city_info"),
    type: "bike",
    bikesInCart: [],
  },
  {
    image: "",
    name: Tr("pickup_fee"),
    price: 400,
    info: Tr("pickup_fee_info"),
    type: "pickup_fee",
    bikesInCart: [],
  }
]

const otherItemsData = [
  {
    image: "https://travelshop.se/_next/image?url=https%3A%2F%2Fcdn3.booqable.com%2Fuploads%2F9928383ce983527ba603efd8356a658c%2Fphoto%2Fphoto%2Fdec71fb3-761d-498f-a073-8328d58fc51a%2Flarge_photo.jpg&w=3840&q=100",
    name: Tr("bag"),
    price: 40,
    info: Tr("bag_info"),
    quantity: 0
  },
  {
    image: "https://travelshop.se/_next/image?url=https%3A%2F%2Fcdn3.booqable.com%2Fuploads%2F9928383ce983527ba603efd8356a658c%2Fphoto%2Fphoto%2F8bcd4d2d-2ce8-40d2-980b-9a8a43a53840%2Flarge_upload.jpg&w=3840&q=100",
    name: Tr("helmet"),
    price: 30,
    calculationType: "fixed",
    info: Tr("helmet_info"),
    quantity: 0
  },
  {
    image: "https://travelshop.se/_next/image?url=https%3A%2F%2Fcdn3.booqable.com%2Fuploads%2F9928383ce983527ba603efd8356a658c%2Fphoto%2Fphoto%2F2778c8cf-f37f-4e31-b04a-4258a0a406fb%2Flarge_photo.jpg&w=3840&q=100",
    name: Tr("child_seat"),
    price: 50,
    info: Tr("child_seat_info"),
    quantity: 0
  },
  {
    image: "",
    name: Tr("baggage_transport"),
    price: 350,
    priceType: Tr("baggage_transport_pricetype"),
    info: Tr("baggage_transport_info"),
    quantity: 0 
  },
  {
    image: "",
    name: Tr("ts_insurance"),
    price: 40,
    priceType: Tr("ts_insurance_pricetype"),
    info: Tr("ts_insurance_info"),
    quantity: 0
  }
] 

export { bikesItemData, otherItemsData };