
const towns = [
  {
    "name": "Helsingborg",
    "information": "Sveriges trevligaste stad. Fantastiska restauranger, bra och varierad shopping och ett stort kulturutbud – i Helsingborg finns något för alla. Här kan du flanera längs Sveriges äldsta gågata, Kullagatan, botanisera bland butikerna och ta en skön fikapaus på något av de mysiga caféerna i centrum. Brinner du för historia, vackra slott eller naturupplevelser lär du inte bli besviken. I centrala Helsingborg kan du beundra utsikten över Öresund från det medeltida tornet Kärnan, besöka vackra Sankta Maria kyrka och göra en utflykt till kungliga Sofiero slott med sin fantastiska slottsträdgård. På Fredriksdals friluftsmuseum kan du vandra i historiska trädgårdar och fascineras av kor, hästar, grisar och får av gamla tiders lantraser, och på Dunkers kulturhus finns spännande utställningar, året om. Helsingborg är en av Sveriges krogtätaste städer. Utbudet är varierat både när det gäller mat och atmosfär – här hittar du allt från mysiga krypin runt Mariatorget till saltstänkta restauranger med underbar utsikt över sundet.",
    "coordinates": { "lat": 56.044942, "lng": 12.693982 },
    "mapBounds": {
      "northEast": { "lat": 56.079840, "lng": 12.752627 }, //GJORD
      "southWest": { "lat": 56.030091, "lng": 12.668513 }
    }
  },
  // {
  //   "name": "Höganäs",
  //   "information": "En kavalkad av upplevelser väntar dig här på Kullahalvön. Höganäs Kullabygden bjuder på flera smultronställen längs Kattegattleden – allt ifrån dramatisk natur och familjevänliga besöksmål, till platser med det där lilla extra som sätter guldkant på din cykeltur. Du passerar många fina badstränder och pittoreska hamnar. Med andra ord: du har mycket att välja mellan när det är dags att sträcka på benen och ta en fikapaus. Mat och dryck står i centrum i Höganäs Kullabygden. Här hittar du ett stort antal caféer, restauranger, vingårdar och gårdsbutiker. För dig som gillar konst och keramik finns också mycket att upptäcka. Passa på att pumpa däcken i Höganäs kommun. Längs leden finns praktiska pumpstationer som hjälper dig vidare på din tur.",
  //   "coordinates": { "lat": 56.200637, "lng": 12.555392 },
  //   "mapBounds": {
  //     "northEast": { "lat": 56.079840, "lng": 12.752627 },
  //     "southWest": { "lat": 56.030091, "lng": 12.668513 }
  //   }
  // },
  {
    "name": "Ängelholm",
    "information": "Aktiviteter och upplevelsernas stad! Sex kilometer sandstrand, kristallklart vatten och skna klitter det är Ängelholm, som ligger vackert beläget längst in i Skälderviken mellan Bjäre- och Kullahalvn, frestar med storslagna naturupplevelser. Rnne å slingrar sig genom staden och inbjuder till såväl paddling som fiske. Har du tur kan du få lax, gädda eller abborre på kroken och passerar du under sommaren rekommenderas en tur med sightseeingbåten Laxen. I en lummig lvskog mitt i staden hittar du Ängelholms Hembygdspark, en härlig oas fr hela familjen. Här finns en djurpark med nordiska djur, fem olika museer och en riktigt mysig lekplats. Vågar du testa klätterbanan hgt uppe bland trädtopparna? Järnvägens museum och Ängelholms flygmuseum är två upplevelsemuseer som passar hela familjen och i centrala Ängelholm hittar du allt från mysiga, små butiker och caféer till restauranger där du kan njuta av lokala råvaror.",
    "coordinates": { "lat": 56.244936, "lng": 12.854674 }, // funkar
    "mapBounds": {
      "northEast": { "lat": 56.21456, "lng": 12.78456 }, //GJORD
      "southWest": { "lat": 56.27094, "lng": 12.97056 }
    }
  },
  {
    "name": "Båstad",
    "information": "Aktiv livsstil året runt. Välkommen till en sällsynt vacker plats med ett rikt utbud av aktiviteter, upplevelser och njutning. I brjan av 1900-talet kom Ludvig Nobel, brorson till Alfred Nobel, till Båstad på Bjärehalvn och blev frälskad i naturens sknhet. Ludvig Nobel såg mjligheterna till turism och utvecklade orten till ett internationellt besksmål. Han satsade bland annat på golf och tennis, och idag hittar du inte mindre än 41 tennisbanor och 126 naturskna golfhål inom bekvämt räckhåll.  På Bjärehalvn finns ver 150 kilometer markerade vandringsleder. Här kan du njuta av saltstänkta promenader, milsvida vyer, trolska bokskogar och glittrande hav. Passa på att stanna till vid de småskaliga gårdsbutikerna där du kan botanisera bland närproducerade varor. Härliga semesterboenden, varmbadhus och spaanläggningar erbjuder dig välfrtjänt avkoppling och återhämtning. Det är enkelt att må bra i Båstad och på Bjärehalvn, året runt.",
    "coordinates": { "lat": 56.433773, "lng": 12.841108 }, // funkar
    "mapBounds": {
      "northEast": { "lat": 56.079840, "lng": 12.752627 },
      "southWest": { "lat": 56.030091, "lng": 12.668513 }
    }
  },
  // {
  //   "name": "Laholm",
  //   "information": "Den lilla staden med de stora konstverken. Laholm är Hallands äldsta stad och i Gamlebys vindlande gränder anas fortfarande medeltiden, medan Stortorget domineras av det vackra rådhuset från 1700-talet. I nästan varje gathörn står en skulptur. Laholm kallas därför ”den lilla staden med de stora konstverken”. Här finns också Teckningsmuseet – Nordens enda museum för teckningskonst. Laholms prisbelönta stadspark är anlagd med mottot ”vilt är vackert”. Här vandrar du genom Vilda skogen, Woodland och Vårbacken. Längs stadskärnan flyter ån Lagan. Njut av rökta delikatesser vid Lagans strand eller följ med på en tur med den anrika ångbåten S/S Lagaholm. I mysiga Laholm finns övernattningsmöjligheter, butiker, caféer och restauranger. Du tar dig enkelt in till Laholms stadskärna från Kattegattleden (cirka 20 minuter med cykel). Leden följer havet och Sveriges längsta sandstrand. I Skummelslövsstrand och Mellbystrand hittar du strandnära restauranger, uteserveringar, caféer, glassbarer och övernattningsmöjligheter. I naturreservaten finns unika sanddyner och trolsk tallskog.",
  //   "coordinates": "56.505903, 13.045112"
  // },
  {
    "name": "Halmstad",
    "information": "Sommarstaden. Residensstaden Halmstad, som fick stadsprivilegier år 1307, är Hallands strsta stad med 100 000 invånare. Halmstad är känd som en av Sveriges bästa sommarstäder och bjuder på många evenemang och ett sjudande folkliv. Kattegattleden passerar genom centrala Halmstad och korsar ån Nissan strax intill Halmstad slott. I slottet från 1600-talet bor landshvdingen. Slottet är inte ppet fr allmänheten, men passa på att vila benen och kika in på borggården. På andra sidan gatan ligger Stora torg som är en perfekt utgångspunkt fr shopping och restaurangbesk. I Halmstad tar du dig lätt runt med cykel. På flera platser i kommunen finns cykelpumpar där du snabbt och enkelt kan fylla på luft utan kostnad.",
    "coordinates": { "lat": 56.669094, "lng": 12.863444 }, // funkar
    "mapBounds": {
      "northEast": { "lat": 56.079840, "lng": 12.752627 },
      "southWest": { "lat": 56.030091, "lng": 12.668513 }
    }
  },

  //below is lat long switched 
  {
    "name": "Falkenberg",
    "information": "Här väntar nya minnen på att skapas. När du cyklar genom Falkenberg br du inte ha fr bråttom, du kommer vilja gra många stopp. Här finns ver 13 km sandstränder, klippbad och vackra utsiktsplatser. Däremellan hittar du en liten stad med ett stort utbud.   Falkenberg har småstadens charm. En promenad genom Gamla stan, där sneda hus kantrar kullerstensgatorna, är ett måste. Här hittar du Tullbron, en av Sveriges vackraste stenbroar. Fortsätt din tur på de naturskna promenaderna runt Ätran. Strax intill ligger barnens favoritställe Vallarna med stor lekplats, mini-zoo, skatepark och en friluftsteater som bjuder på fullspäckat program hela sommaren. På RIAN designmuseum visas svensk och internationell design. Shoppar gr du bäst längs Storgatan och Nygatan. Falkenberg är en erkänd matdestination och här finns allt från mysiga caféer till gourmetrestauranger. Närodlade råvaror ligger restaurangerna varmt om hjärtat och du kan beska flera gårdsbutiker.",
    "coordinates": { "lat": 56.900044, "lng": 12.496191 }, // funkar
    "mapBounds": {
      "northEast": { "lat": 56.079840, "lng": 12.752627 },
      "southWest": { "lat": 56.030091, "lng": 12.668513 }
    }
  },
  {
    "name": "Varberg",
    "information": "Mitt i historien med en särskild känsla för vatten. I Varberg finns en alldeles särskild kärlek till vatten. Redan under tidigt 1800-tal var staden en känd kurort dit folk vallfärdades för att dricka brunn, och vattnet har fortfarande en central ställning: i Varberg kan du njuta av allt från avkopplande spa-behandlingar till uppfriskande nakenbad och pulshöjande surfing. Kattegattleden sträcker sig längs den vackra Strandpromenaden från Apelviken och surfarnas mecka, de omtalade nakenbaden, förbi historiskt mäktiga Varbergs fästning. Ta cykeln till Varbergs centrum och upplev stadens livfulla torgdagar, eller hoppa på bussen för en dagsutflykt till Åkullas vackra natur med bokskog och spännande besöksmål.",
    "coordinates": { "lat": 57.108251, "lng": 12.247251  }, // funkar
    "mapBounds": {
      "northEast": { "lat": 56.079840, "lng": 12.752627 },
      "southWest": { "lat": 56.030091, "lng": 12.668513 }
    }
  },
  {
    "name": "Kungsbacka",
    "information": "Kungsbacka grundades i början av 1500-talet och var fram till 1645 danskt. De gamla trähusen präglar stadskärnan och bjuder på en pittoresk atmosfär med caféer, restauranger och butiker. Röda Stugan är stadens äldsta byggnad och är en av två fastigheter som idag visar hur Kungsbacka såg ut före stadsbranden 1846. Huset ligger ett stenkast från Kattegattleden och det vackra torget som kantas av uteserveringar under sommaren.   Från Tölöberg, Kungsbackas äldsta vattentorn från 1897, har du utsikt över nästan hela Kungsbacka. Kungsbackaån slingrar sig genom staden och mynnar ut i Kungsbackafjorden. På andra sidan fjorden, alldeles vid havet, ligger Tjolöholm Slott, Sveriges enda Tudorslott.  Kungsbacka är en av Västsveriges största handelsplatser. Det genuina och traditionella lever fortfarande kvar, sida vid sida med de nya moderna shoppingcentren.",
    "coordinates": { "lat": 57.486774, "lng": 12.076287}, // Bör göras bättre // funkar
    "mapBounds": {
      "northEast": { "lat": 56.079840, "lng": 12.752627 },
      "southWest": { "lat": 56.030091, "lng": 12.668513 }
    }
  },
  {
    "name": "Göteborg",
    "information": "Göteborg är Sveriges näst största stad och grundades av 1621 av Gustav II Adolf, precis där Göta älv möter havet. Stadskärnan är mysig och kompakt, och har en sicksackformad vallgrav som kryssar genom centrum. Gillar du fisk och skaldjur har du kommit helt rätt. Göteborg har ett rikt restaurang- och kaféliv, med Saluhallen och Feskekörka som två av stadens mest älskade landmärken. Läget vid havet påverkar inte bara matutbudet, närheten till skärgården öppnar också upp för många fina utflykter. Ta en båttur ut över dagen eller stanna över natten på någon av öarna. Här kan du njuta av sköna klippbad, vandringar utmed horisonten eller följa med på en krabbfisketur. Göteborg har även ett myllrande shoppingutbud och kulturlivet erbjuder allt från museer till opera, teater och musik.",
    "coordinates": { "lat": 57.708223, "lng": 11.97353 }, // funkar
    "mapBounds": {
      "northEast": { "lat": 56.079840, "lng": 12.752627 },
      "southWest": { "lat": 56.030091, "lng": 12.668513 }
    }
  }
]

export default towns;