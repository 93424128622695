import Popup from 'reactjs-popup';
import React, { useState, useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import 'reactjs-popup/dist/index.css';
import crossButtonIconUrl from '../../../img/svg/cross_button_icon.svg';
import emailjs from 'emailjs-com';
import SyncLoader from 'react-spinners/SyncLoader';
import './PopupInfo.css';
import { Tr } from '../../../translations/translation';

const PopupBookingReservation = ({ bookingData, onFinishedBooking, isOpen, setIsOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [emailString, setEmailString] = useState("");
  const closeModal = () => setIsOpen(false);

  useEffect(() => emailjs.init(process.env.REACT_APP_EMAILJS_KEY), []);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const travelShopHtmlContent = renderToStaticMarkup(travelShopReservation(true));
    const customerHtmlContent = renderToStaticMarkup(customerReservationContainer(true));

    const templateParamsTravelShop = {
      to_name: "Travelshop",
      to_email: "booking@bikehotelplanner.com",
      from_name: bookingData.name,
      from_email: bookingData.email,
      message: travelShopHtmlContent
    };

    const templateParamsCustomer = {
      to_name: bookingData.name,
      to_email: bookingData.email,
      from_name: "Bike hotel planner",
      from_email: "booking@bikehotelplanner.com",
      message: customerHtmlContent
    };

    Promise.all([
      emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, templateParamsTravelShop),
      emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, templateParamsCustomer)
    ])
    .then((responses) => {
      responses.forEach(response => {
        console.log('SUCCESS!', response.status, response.text);
      });
      setEmailString(Tr("reservation_email_sent"));
    })
    .catch((err) => {
      setEmailString(Tr("something_wrong") + ": " + err.message);
    })
    .finally(() => {
      setIsLoading(false);
      setTimeout(() => {
        onFinishedBooking();
        closeModal();
      }, 3000);
    });
  };

  const createOtherBookingData = (otherData) => {
    const otherEmailData = otherData.map((item) => {
      if (item.quantity > 0) {
        return `${item.quantity}st ${item.name}`;
      }
      return null;
    }).filter(item => item !== null);
  
    return otherEmailData;
  }

  const addLineBreaks = (description) => {
    return description.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index !== description.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const reservationTextContainer = ({ isEmail, title, infomation, contactTitle, reservationTitle }) => {
    const readableOtherData = createOtherBookingData(bookingData.otherData);
    const allBikesInCart = bookingData.bikesData.reduce((acc, bike) => {
      return acc.concat(bike.bikesInCart.map(bikeInCart => `${bike.name} ${" - "} ${Tr("height_cyclist")}: ${" "} ${bikeInCart.height} ${"cm"}`));
    }, []);

    return( 
    <div className="">
      <h2 className='text-lg font-bold mb-2'>{ title }</h2>
      {isEmail && <p className='text-md mb-2'>{ addLineBreaks(infomation) }</p>}
      <h2 className='text-md'><strong>{ contactTitle }:</strong></h2>
      <p>{bookingData.name}</p>
      <p>{bookingData.email}</p>
      <p>{bookingData.phone}</p>
      <p>{bookingData.comment}</p>
      <br/>
      <h2 className='text-md mb-1'><strong>{ reservationTitle }:</strong></h2>
      {allBikesInCart && allBikesInCart.map((item, index) =>
        <p key={index}>{item}</p>
      )}
      {readableOtherData && readableOtherData.map((item, index) =>
        <p className='mb-1' key={index}>{item}</p>
      )}
      <p>{bookingData.pickupFee ? Tr("pickup_fee") : ""}</p>
      {isEmail && <p>{ Tr("email_greeting") }</p>}
      <h2 className='text-lg mt-2 mb-2'><strong> {Tr("total")}: {bookingData.totalCost}{Tr("currency")}</strong></h2>
    </div>
    )
  }

  const displayReservationContainer = () => {
    return( 
    <div className="">
      {customerReservationContainer(false)}
      <div className="mb-4">
          <span className="text-sm">{Tr("popup_booking_reservation_text")}</span>
          </div>
      <div className='flex'>
      <button className='submit-button close-button mr-10' onClick={handleSubmit}>{Tr("popup_booking_button")}</button>
      <div className='mt-4'>
      {isLoading && <SyncLoader color="#36d7b7" size={10} />}
      {emailString.length > 0 && <p>{emailString}</p>}
      </div>
      </div>
    </div>
    )
  }

  const travelShopReservation = (isEmail) => {
    return( 
      reservationTextContainer({
        isEmail: isEmail,
        title: Tr("email_resevation_bikeshop_title"),
        infomation: Tr("email_resevation_bikeshop_infomation"),
        contactTitle: Tr("email_resevation_bikeshop_contact_title"),
        reservationTitle: Tr("email_resevation_bikeshop_reservation_title")
      })
    )
  }

  const customerReservationContainer = (isEmail) => {
    return(
      reservationTextContainer({
        isEmail: isEmail,
        title: Tr("email_customer_resevation_title"), 
        infomation: Tr("email_customer_resevation_infomation"),
        contactTitle: Tr("email_customer_resevation_contact_title"), 
        reservationTitle: Tr("email_customer_resevation_reservation_title")
      })
    )
  }

  return (
    <div>
      <Popup 
        open={isOpen} 
        closeOnDocumentClick 
        onClose={() => closeModal()} 
        contentStyle={{width: "500px", height:"auto"}}
      >
        <div className="base-container">
          <a className= {isLoading ? "is-loading" : "close"} onClick={() => closeModal()}>
            <img className='cross-button' src={crossButtonIconUrl} alt="cross" />
          </a>
          {bookingData && displayReservationContainer()}
        </div>
      </Popup>
    </div>
  );
}

export default PopupBookingReservation;