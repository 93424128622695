export const english = {
  "from": "From",
  "to": "To",
  "date": "Date",
  "time": "Time",
  "h1_title" : "Your perfect bike trip on Kattegattleden",
  "subtitle" : "Enjoy your bike holiday and save money",
  "helmet_title" : "Kattegattleden | Plan your bike trip",
  "start_date": "Start date",
  "no_adults": "Number of adults",
  "no_children": "Number of children",
  "no_rooms": "Number of rooms",
  "no_nights" : "Number of nights",
  "calculate_route": "Calculate route",
  "back": "Back",
  "close": "Close",
  "reserve_bikes": "Reserve bikes",
  "comment": "Comment",
  "continue": "Continue",
  "skip": "Skip",
  "something_wrong": "Something went wrong",
  "total_bikes": "Total bikes",
  "bike_size": "Bike size",
  "email": "Email",
  "phone": "Phone",
  "name": "Name",
  "reservation_info": "Reservation info",
  "bike_distance": "Bike distance",
  "route_info": "Info",
  "reservation_email_sent": "Your reservation has been sent",
  "currency": "SEK",
  "reserve_hotel_for" : "Reserve hotel for",
  "hotel_in" : "Show hotels in ",
  "bike" : "Bike",
  "baggage" : "Luggage",
  "day": "Day",
  "overview" : "Overview" ,
  "total_distance" : "Total distance",
  "height" : "Height",
  "route_description" : "Route description",
  "your_booking_for": "You are booking for {{params.value1}} persons for dates {{params.value2}} - {{params.value3}}",

  "accessories": "Accessories",
  "addons": "Addons",
  "total_addons": "Total accessories & addons",
  "total": "Total",

  //result view
  "result_title": "Your bike trip",
  "result_text": "Follow the links below and book your hotel for each destination according to the list. Remember to always book with free rebooking and cancellation in case it becomes difficult to reserve the desired hotels in the respective locations.",
  "result_input_text": "Please fill in your details below to receive your travel itinerary via email.",
  "result_send_button": "Send result to mail",
  "result_bike_order_text": "You have not ordered any bikes",
  "result_bike_order_button" : "Book bikes",

  "hotel_town": "Overnight city",
  "hotel_date": "Overnight date",
  "hotel_link": "Hotel link",
  "hotel_add_hotel": "Add your booked hotel here:",

  // transport & accessories
  "transport_title": "Reserve luggage transport and accessories",
  "total_bike_cost" : "Total bikes",

  //error messages
  "email_error_nothing": "Please fill in your email",
  "email_error_invalid": "Please fill in a valid email",
  "phone_error_nothing": "Please fill in your phone number",
  "phone_error_invalid": "Please fill in a valid phone number",
  "name_error_nothing": "Please fill in your name",
  "result_error_database": "Something went wrong when we tried to add your user to the database",
  "result_email_sent" : "Your travel plan has been sent",

  // email reservation bikeshop
  // should always be in swedish to travelshop but other language when contacting other bikeshops
  "email_resevation_bikeshop_title" : "Kundens bokning",
  "email_resevation_bikeshop_infomation" : "Här kommer en reservation från Bike Hotel Planner",
  "email_resevation_bikeshop_contact_title" : "Kundens uppgifter",
  "email_resevation_bikeshop_reservation_title" : "Booking and details",

  // email customer reservation
  "email_customer_resevation_title": "Your reservation confirmation",
  "email_customer_resevation_contact_title": "Your details",
  "email_customer_resevation_infomation" : `-	The booking has been sent to our partner who has the bikes on site.
	-	They will book them for you and send a secure link for payment.
	-	The reservation is binding only after payment has been made.
	-	If you book at least 7 days before departure, we guarantee bike availability.
	-	Ensure the booking of accommodation before paying for the bikes.
	-	Your booking is not fully completed until our bike supplier has confirmed the order. This can take up to 48 hours.`,
  "email_customer_resevation_reservation_title": "Your reservation",

  // email travel plan
  "email_travelplan_title" : "Your travel plan",
  "email_travelplan_link_title" : "Press here to view your travel plan",
  "email_travelplan_text" : "Your travel plan has been created and can be viewed when you follow this link:",
  "email_greeting" : "Hope you have a wonderful trip!",

  // info popup text
  "reservation_info_title": "How does the reservation work?",
  "reservation_info_text": `- When you reserve bikes and accessories, the reservation is sent to our partner who has the bikes on site.
  - The bikes are booked in and a secure payment link is sent to you.
  - The reservation is only binding after payment. 
  - If you book at least 7 days before departure, we guarantee bike availability.
  - Ensure to book your accommodation before paying for the bikes.
  - After the reservation is confirmed, you will receive a confirmation email. Your booking is not complete until our bike provider confirms the reservation. This can take up to 48h.`,

  "planner_info_title": "How does it works",
  "planner_info_text": `With our bike trip planner, we make it easier for you to:
  Book hotels on the correct dates and locations along the route.
  Save money by booking directly through our service.
  Receive a convenient overview and GPS file with the route and hotel locations.
  Choose each individual hotel and price range.
  Enter dates, number of adults, number of rooms, departure date, and calculate the route.
  In the next step, you can fill out your request to reserve bicycles, accessories, and luggage transfers between your hotels. You can also skip this step and come back to it later.
  Once your trip is planned, you can proceed to book the hotels on hotels.com or booking.com.`,

  "places_info_textfield_title": "Add booked hotels",
  "places_info_textfield_text": `To customize your itinerary with a marked hotel or if you have selected baggage transport, we need this information.`,

  //info popup booking reservation
  "popup_booking_reservation_text": `The request is sent to the provider who will return within 48h with a confirmation of the reservation and a secure payment link. 
  By paying, you confirm the reservation and follow the terms and conditions sent with the reservation.`,
  "popup_booking_button": "Confirm & reserve",

  // bike cart items
  "touring_bike_name": "Touring bike",
 "touring_bike_info": "A touring bike is a hybrid bicycle with a more comfortable riding position, where the handlebars and saddle are at the same height or even with the handlebars slightly above the saddle. Touring bikes are designed for this purpose and do also have good luggage capabilities. If you are considering sizes and are between two sizes, the smaller size is usually preferable because a smaller frame is easier to handle and the handlebars are closer to the saddle. This allows you to adjust the saddle height more appropriately for you. ",

  "touring_bike_electric_name": "Electric Touring bike",
  "touring_bike_electric_info": "The electric touring bike is generally the same kind of bike style as the Touring bike with the addition of an electric motor for your assistance.",

 "electric_city_name": "E-Bike 7-speed",
 "electric_city_info": "A comfortable classic 7-speed bicycle with a very comfortable riding position that you are surely familiar with. Additionally, it is an e-bike with a front-wheel motor that easily assists you along your journey.",

"standard_name" : "7-speed city bike",
"standard_info" : "A 7-speed city bike A classic everyday bicycle with a comfortable riding position, 7 gears, and coaster brakes.",


  // accessories cart items
  "bag": "Bag",
  "bag_info": "A bike bag is waterproof and holds 20-25 liters. You can easily hang it on the luggage rack. If you do not book luggage transport and stay in a hotel or similar, you probably need two bags per person.",
  "helmet": "Helmet",
  "helmet_info": "A helmet",
  "child_seat": "Child seat",
  "child_seat_info": `The child seat can be mounted on almost all bikes and is suitable for children who can sit independently, up to a maximum of 25 kg. Specify at booking which bike should have the child seat.`,
  "baggage_transport": "Luggage transport",
  "baggage_transport_info": `If you book luggage transport, you will receive a luggage tag. Please enter your overnight locations in the form . The luggage must be deposited at the reception from 9:30 AM. The bags will be delivered in the afternoon for your next overnight stay.`,
  "baggage_transport_pricetype": "kr/person",
  "ts_insurance": "Emergency protection",
  "ts_insurance_info": "With emergency protection, you get help up to 2000 SEK insured. There is a repair kit and an emergency number. If necessary, we will replace the bike, but cannot guarantee the same type.",
  "ts_insurance_pricetype": "kr/period",

   // route info
   "route_1_info": `Today you ride across the beautiful Kulla Peninsula, filled with art, farm shops, and flea markets. The route mixes gently rolling open fields and high, hilly landscapes with fantastic views. After leaving Helsingborg and reaching Sofiero Castle and its gardens after a short climb, take a break. Particularly if you're interested in gardens, but also because of the beautiful castle. The journey continues through small, picturesque villages and small ports like Domsten and Viken before reaching Höganäs. Höganäs is well-known in Sweden for its long history of ceramic production. The route continues across the scenic Kulla Peninsula. At its far end lies the Kullaberg Nature Reserve with the Kullen Lighthouse, a side trip you won't regret. 
   The highest point - Håkull - is 187 meters above sea level. One of Sweden's new wine-growing regions lies on the Kulla Peninsula, with several vineyards you can visit. As you approach the sea again, you pass through the communities of Arild, Skäret, Jonstorp, and Norra Häljaröd, with several nice cafes along the way. There are also several restaurants, snack bars, and cafes here. Don't miss having a coffee at Flickorna Lundgren in Skäret. Before reaching Ängelholm, you cycle through several small, cozy coastal communities.`,
   "surface_1": "90% Asphalt, 10% Gravel",
 
   "route_2_info": "Xxxxxxx", //TODO
   "surface_2": "76% Asphalt, 24% Gravel",
 
   "route_3_info": "This part of the Kattegattleden is the hilliest stretch. Enjoy the beautiful views of the sea and the varied agricultural landscape of the Bjäre Peninsula. The Kattegattleden passes through the center of Ängelholm, right next to the train and bus station, and takes you down to the beautiful district of Havsbaden. Here you'll find forests, untouched nature, and a wonderfully wide and long sandy beach. Stop at the beach kiosk and try Engelholms Glass, the city's own proud ice cream brand. The journey continues along the coast towards Skälderviken, passing through small villages and fishing villages. You pass Skepparkroken and Vejbystrand before turning towards Killebäckstorp after picturesque Torekov. Here you'll find cafes, restaurants, and you can also take the ferry to Hallands Väderö. From Torekov, the tour continues into the heart of Bjäre. Here awaits you a fragmented agricultural landscape typical of the Bjäre Peninsula, shaped and based on livestock farming since prehistoric times. You meet the sea again at Norrviken Garden and continue along the path to the port of Båstad.",
   "surface_3": "76% Asphalt, 24% Gravel",
 
   "route_4_info": "About the Stage: This section of the route passes through Sweden's longest sandy beach and through green nature reserves. From the port of Båstad, follow the sea until you reach the pine forest in Hemmeslöv and cross the border between the regions Halland and Skåne. Now you have an easy stretch along the long sandy beach of Laholmsbukten. In the familiar Skummeslövsstrand, you'll find restaurants and cafes. Here the sea is shallow and the landscape is open. A few kilometers inland lies the beautiful Skottorp Castle and the cozy Laholm, the oldest town in the region. Plan about 20 minutes off the route to Laholm. A few kilometers north of Skummeslövsstrand you reach Mellbystrand, a seaside resort with memories of past times. Here the dunes are higher and the sea is wilder. You pass mini golf courses and outdoor restaurants. The route continues past Laxvik and out into the open countryside south of Halmstad. Before leaving the sea, you can stop at Påarps Klapperstensfeld with 220 graves from the Iron Age. The path then leads through Östra-Strand before reaching Halmstad Castle.",
   "surface_4": "75% Asphalt, 25% Gravel",
 
   "route_5_info": `About the Stage: The journey continues north to one of Sweden's most famous and beautiful beaches, Tylösand. If you don't want to swim on the lifeguard-protected beach, I recommend visiting Tylösand Hotel. The hotel features Sweden's largest photo art gallery and a "museum" showcasing parts of Gyllene Tider and Roxette's history. The route follows the coast north to Haverdal and Särdal. Stop at the coastal station Steninge for coffee and cake at Plätteriet or Bikebar. You don't have to ride far before reaching Ugglarps Havsbad and Långasand, both offering swimming and restaurants. After a short ride inland, you'll be back on the coast and Grimsholmen. Here is ideal for a stop and a short walk to the water, perhaps enjoying a picnic you brought along. You continue riding and soon arrive in Falkenberg and Skrea Beach.`,
   "surface_5": "94% Asphalt, 6% Gravel",
 
   "route_6_info": `The Falkenberg-Varberg route is an easily accessible part of the Kattegattleden, only 40 km long and without notable climbs. You cycle through several small communities and villages. Stop in Olofsbo to eat and drink at Restaurant Kvarnen before cycling to Morups Tånge Nature Reserve to watch birds in the beautiful lighthouse. Ride a little further past the old fishing village of Glommen in Morup. In Morup you can visit the bride crown Macher Britten Toftarp. You continue cycling on a relatively newly opened bike path, passing Björkäng Campsite and in Utterås you will find “Rit und Målerigården”. Eat a waffle and admire all the art, maybe buy something and have it sent home right away. You are approaching Träslövsläge, a fishing village with several restaurants. Here you must try the wonderful freshly cooked emperor lobster. The route continues to the promenade and when you have passed Varberg Fortress, you will reach the port of Varberg with the cold bath house and the social park.`,
   "surface_6": "97% Asphalt, 3% Gravel",
 
   "route_7_info": `The route continues along the coast, through meadows, forests and agricultural fields. This section is slightly hilly, but without major climbs. Just north of Varberg is the “Naturum Getterön” information center, a must for bird lovers. You cycle through small communities and soon reach Bua, an old fishing village. There are several eateries serving fish and seafood in the harbor. The route continues past the Ringhals nuclear power plant, but also through beautiful beach forests. You continue along the coast and pass Kärradal, Stråvalla and Frillesås, all with excellent restaurants, cafes and beaches. Stop in Åsa and why not enjoy an ice cream at Åsa "Glasscafé"? In Åsa, the route continues inland and you cycle on small roads. Just south of Fjärås you can deviate from the route and take a trip to Tjolöholm Castle, Sweden's only Tudor-style castle. You continue cycling through fields and horse farms. In Kungsbacka, the route passes through the city, which is an old trading place.`,
   "surface_7": "95% Asphalt, 5% Gravel",
 
   "route_8_info": `From the center of Kungsbacka, you can either follow the signs for Kattegattleden towards Onsala or take the bike path along Road 158 to Vallda, where you will rejoin Kattegattleden. This alternative route shortens the distance to Gothenburg by about 20 km. If you follow the route to Onsala, you'll cycle along the Kungsbacka Fjord. In Onsala, you can visit the Boat and Maritime Museum and learn more about "Lasse i Gatan," the privateer captain of King Lars Gathenhielm. Continue to Gottskär to enjoy an ice cream and a stroll in the beautiful harbor. Cycle further along the Onsala Peninsula to Särö. From Särö, you'll ride on the old railway line of Säröbanan, which runs along the coast. You'll pass many small communities with restaurants, cafes, and plenty of swimming spots. North of Billdal, follow the coast and enjoy a beautiful view of the archipelago. Ride out to Lilla Amundö, stretch your legs, and enjoy nature. Closer to Gothenburg, you'll pass the cultural reserve Klippan and the art hall Röda Sten. At Järntorget, turn towards the city, and the route continues along Kungsparken, ending at Drottningtorget near the Central Station.`,
   "surface_8": "98% Asphalt, 2% Gravel",

   "hotel": "Hotel",
   "arrival": "Arrival",
   "height_cyclist": "Height of cyclist",

   "book_hotel_info_title" : "Here's how to book hotels!",
   "book_hotel_info_text" : `Follow one of the links for each destination along your journey to the external booking page. Then, book a hotel for each destination. Remember to choose a booking option that allows cancellation, so you don't find yourself without accommodation for any part of your trip.
   Once you've booked the hotel, return to our site and enter the hotel into the list, saving it.
   After saving a hotel, you can immediately see it on the map, where you'll also view the route along the trail.
   Once all hotels are booked, you can send the list to your email to keep it handy.
   You can also go back and book bikes, accessories, and luggage transfers if you haven't already done so.`,
   

   "error_baggage_transport" : "If you have booked luggage transportation, you need to add each booked hotel in the form to update the map with all destinations. The information will be forwarded directly to the local provider so they can pick up and drop off your luggage during the tour.",
   
   "height_error" : "Please enter the height of the persons who will be cycling, for each bike.",
   "height_wrong_error" :  "It seems you have entered an invalid height.",
  
   "privacy_policy_title" : "Terms and Services",
  "privacy_policy_text" : `Booking Bicycles and Accessories
  What happens when I submit a booking inquiry for bicycles and accessories? When you submit a booking inquiry through our website, it is immediately forwarded to the supplier. Within 48 hours, you will be contacted with information on availability, the final price, and answers to any additional questions you have. If you submit your request at least 7 days before departure, the supplier will confirm your request. You can then choose to accept or decline the booking through a link provided by the bicycle supplier.
  Is it more expensive to book through you than directly with the organizer? No, our business model is based on receiving a commission when you book through our links or submit an inquiry for bicycles. Neither you nor the organizer will incur any additional fees. The price remains the same as if you had booked directly with the organizer.
  It's been more than 24 hours since I submitted my inquiry and I haven't received a response. What should I do? If more than 48 hours have passed and you have not received a response, please contact us via email so we can assist you further. Also, check your spam folder to ensure the response did not end up there.
  How do I cancel a booking? For bicycles, the booking is only confirmed when payment is made via the link and confirmation you receive from the rental company. If you have not paid, you have not committed to anything. Cancellation policies vary depending on the departure date, and you will receive all details from the supplier.
  What services do you offer?
  We provide a platform where you, as a customer, can independently book and pay for accommodations along your cycling route. We also assist in forwarding booking inquiries to local providers of rental bicycles. The provider will respond to your inquiry within 24-48 hours, and payment and conditions follow the policy of the local provider.
  How does hotel reservation work?
  We simplify your process of booking accommodations along your cycling route by aggregating suggestions from various facilities on a platform, such as booking.com or hotels.com. When booking, it's important to choose rooms with free cancellation options for flexibility during your trip.
  I want assistance with booking
  If you ask us for help in creating a travel proposal, we will not charge you as a customer. Payment for the hotel is made directly to the hotel during your stay, and we never handle your payment information. We reserve the hotels for you, and the booking of bicycles works in the same way as described above.
  Package tours and travel guarantee
  We help you find suitable hotels along your desired cycling route. However, since we do not handle payments for bicycles, we do not offer package tours or travel guarantees. Bicycle bookings are made through our partners within 48 hours, but at least 24 hours after the hotel reservation.`,

  "pickup_fee" : "Pickup fee",
  "pickup_fee_info" : "If you want to leave the bikes in an other place than Helsingborg, a fee is charged of 400 SEK."
} 