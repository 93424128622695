import Popup from 'reactjs-popup';
import React, { useState } from 'react';
import 'reactjs-popup/dist/index.css';
import InfoIcon from '../icons/InfoIcon';
import crossButtonIconUrl from '../../../img/svg/cross_button_icon.svg';
import infoData from '../../data/info_data';
import './PopupInfo.css';
import { Tr } from '../../../translations/translation';

const PopupInfo = ( { type, hasButtonTitle = true, isWide = false } ) => {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const info = infoData.find(item => item.type === type);

  const addLineBreaks = (description) => {
    return description.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index !== description.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const infoContainer = () => {
    return (
      <div className=''>
        <br />
        <br />
        <h2 className='text-lg font-bold mb-2'>{info.title}</h2>
        <p className='mb-5'>{addLineBreaks(info.text)}</p>
        <button className='submit-button close-button ' onClick={closeModal}>{Tr("close")}</button>
      </div>
    );
  }

  const buttonType = () => {
    return <div onClick={() => setOpen(o => !o)} className="text-teal-7000 text-right block booking-info-button">
      {hasButtonTitle ? info.title : null}
      {type !== "privacy_policy" ? <InfoIcon size={30}/> : null}
    </div>
  }

  return (
    <div>
      {buttonType()}
      <Popup 
        open={open} 
        closeOnDocumentClick 
        onClose={closeModal} 
        contentStyle={{width: isWide ? "80%" : "500px", height:"auto"}}
      >
        <div className="base-container">
          <a className="close mb-2" onClick={closeModal}>
            <img className='cross-button' src={crossButtonIconUrl} alt="cross" />
          </a>
          {infoContainer()}
        </div>
      </Popup>
    </div>
  );
}

export default PopupInfo;
