const isTesting = false;
const showMap = true;

const bikeReservationName = () => {
  if (isTesting) {
    return "Johannes";
  } else {
    return "";
  }
}

const bikeReservationPhone = () => {
  if (isTesting) {
    return "0729449238";
  } else {
    return "";
  }
}

const bikeReservationEmail = () => {
  if (isTesting) {
    return "olsson.johannes@gmail.com";
  } else {
    return "";
  }
}

export { showMap, isTesting, bikeReservationName, bikeReservationPhone, bikeReservationEmail }