import { Tr } from "../../translations/translation";

const routeData = {
    "generalInformation": "I Helsingborg startar eller slutar Kattegattleden vid Informationstavlan utanför Centralstationen, där tågstationen, färjeläget och långtidsparkering finns och etappen slutar vid Höganäs hamn. Tänk på att kolla upp öppettider på de besöksmål och anläggningar du tänkt besöka och boka gärna ditt boende i förväg! Allemansrätten är en unik möjlighet för alla att röra sig fritt i naturen. Men du behöver också ta ansvar för natur och djurliv och visa hänsyn mot markägare och andra besökare. Alltså inte störa – inte förstöra. Allemansrätten är begränsad i nationalparker och naturreservat. Det är alltid du som besökare i naturen som har ansvaret att känna till vad som gäller i området som du besöker. Tänk också på att hålla cykelleden fri från nedskräpning – ta med ditt skräp till närmaste soptunna!",
    "routes": [
      {
        "route": "route_1",
        "name": "HELSINGBORG - ÄNGELHOLM",
        "from": "Helsingborg",
        "to": "Ängelholm",
        "distance": "60 km",
        "status": "Det finns inga kända problem längs etappen.",
        "foodAndDrinks": "Gårdsbutiker och god mat är utmärkande inslag kring Helsingborg och Höganäs, då odling och jordbruk är en viktig del i denna region. Njut av havets läckerheter hos Christians Fiskebod i Domstens hamn. Strax söder om Domsten cyklar du förbi Kulla Gunnarstorp Gårdsbutik som erbjuder både närodlat, blommor och inredning. På Sofiero Gård, strax söder om Sofiero Slott finner du surdegsbageriet Brödkultur med café, som även serverar lättare frukost och lunch. För goda våfflor gör en avstickare till Pålsjöpaviljongen Café & Våffelbageri. I Viken rekommenderas en avstickare för ett besök hos Vikentomater, här kan du botanisera bland frukt, bär och grönsaker från den egna gården. Vad sägs om melon, över 100 olika sorters tomater och jordgubbar? Här kan du också avnjuta en läcker lunch tillagad på det som odlas här. Inne i Höganäs ligger området Salthallarna, här finns bland annat ett café med egen tillverkad Italiensk gelato och chokladpraliner, Garage Bar som serverar en av världens godaste burgare och ett microbyggeri.",
        "information": "Från Helsingborg cyklar du norrut, passerar Sofiero slott och de långa, härliga stränderna vid Domsten och Viken. Leden går längs vattnet, och utsikten ver ett glittrande Öresund är minst sagt magisk. Kattegattleden brjar – eller slutar – i en av Nordens äldsta städer, Helsingborg. Den frsta sträckan tar dig från stadskärnan till Grningen och Fria bad, två populära badplatser som lockar med glass, sol och bad. Sitt ner och njut ett tag, fr snart kommer ledens frsta uppfrsbacke. Lite längre fram ligger Sofiero slott och Sofiero Gård, med bageri och café, som är väl värt ett besk. Leden går vidare norrut och passerar små pittoreska fiskelägen och småbåtshamnar som Domsten och Viken. Strax utanfr Viken kan du gra en avstickare till Beredskapsmuseet, Sveriges enda museum som huserar i en bevarad underjordisk frsvarsanläggning från andra världskriget. Cykla vidare mot Hganäs och njut av utsikten ver Öresund innan du kommer fram till Kvickbadet, strax sder om Hganäs hamn.",
        "information2": Tr("route_1_info"),
        "surface": Tr("surface_1")
      },
      {
        "route": "route_2",
        "name": "ÄNGELHOLM - BÅSTAD",
        "from": "Ängelholm",
        "to": "Båstad",
        "distance": "55 km",
        "status": "Det finns inga kända problem längs etappen.",
        "foodAndDrinks": "",
        "information": Tr("route_3_info"),
        "surface": Tr("surface_3")
      },
      {
        "route": "route_3",
        "name": "BÅSTAD - HALMSTAD",
        "from": "Båstad",
        "to": "Halmstad",
        "distance": "42 km",
        "status": "För att frigöra yta för byggandet av Södra infartens passage över Västkustbanan och anslutande hamnspår har en temporär väg med cykelväg byggts parallellt med Stålverksgatan. Läs mer och se karta här. Risk för vattenfylld viadukt under E6:an väster om Trönningen. Vid dessa tillfällen hänvisar vi till viadukten söder om nuvarande sträckning.",
        "foodAndDrinks": "",
        "information": Tr("route_4_info"),
        "surface": Tr("surface_4")
      },
      {
        "route": "route_4",
        "name": "HALMSTAD - FALKENBERG",
        "from": "Halmstad",
        "to": "Falkenberg",
        "distance": "57 km",
        "status": "Det finns inga kända problem längs etappen.",
        "foodAndDrinks": "",
        "information": "Kust och hav, öppna landskap, natursköna och fågelrika naturreservat, trevliga små gårdsbutiker… På den sköna sträckan mellan Halmstad och Falkenberg finns mycket att upptäcka. Cyklingen fortsätter västerut mot en av västkustens mest älskade stränder; Tylösand. Här badar du extra tryggt eftersom stranden övervakas av professionella livräddare hela sommaren. Tylösand följs av Frösakull, Ringenäs och Vilshärads stränder innan leden svänger österut. Vid väderkvarnen i Särdal finns ett mysigt café och en gårdsbutik. Kring den charmiga byn Steninge finns flera vackra naturreservat med bland annat unika strandängar. Stanna gärna till på Steninge Kuststation för en fikapaus i Plätteriet eller i deras BIKEBAR. Leden tar dig vidare på Kustvägen till Ugglarp, där du kan göra en avstickare till Svedinos Bil- & Flygmuseum som har en av Europas största samlingar av gamla bilar och flygplan. Unna dig en skön bensträckare vid Ugglarps havsbad. Du cyklar vidare inåt landet och passerar böljande jordbrukslandskap. Här kan du stanna till vid utkiksplatsen Smörkull och Grimsholmens naturreservat innan du kommer fram till populära Skrea strand med sin långa badbrygga.",
        "information2": Tr("route_5_info"),
        "surface": Tr("surface_5")
      },
      {
        "route": "route_5",
        "name": "FALKENBERG - VARBERG",
        "from": "Falkenberg",
        "to": "Varberg",
        "distance": "39 km",
        "status": "Det finns inga kända problem längs etappen.",
        "foodAndDrinks": "",
        "information": "Sträckan Falkenberg – Varberg är en lättcyklad del av Kattegattleden. Här cyklar du genom öppna landskap och upplever såväl orörd natur som populära stränder. Efter Falkenberg rullar du vidare längs en kuststräcka som verkligen ger liv åt uttrycket ”öppna landskap”. Här kan du stanna till vid mindre kända badplatser som Stafsinge strand och Petters brygga, eller njuta av stränder med mer liv och rörelse som Olofsbo, Björkäng och Apelviken. Perfekt när du behöver fylla på med glass eller en bit mat. Gör små stopp på vägen – här finns ett rikt fågelliv och flera naturreservat. Gör också en avstickare till Morups Tånge med den imponerande fyren som spridit sitt ljus över havet sedan 1843. På din väg passerar du också flera mysiga fiskelägen som Lövstaviken, Glommen och Träslövsläge, alla väl värda ett besök. När du närmar dig Varberg passerar du Apelviken, stranden som lockar massor av vindsurfare från hela Europa. Leden tar dig vidare till Strandpromenaden och förbi Varbergs fästning innan du når Varbergs Hamn med Kallbadhus och Societetspark.",
        "information2": Tr("route_6_info"),
        "surface": Tr("surface_6")
      },
      {
        "route": "route_6",
        "name": "VARBERG - KUNGSBACKA",
        "from": "Varberg",
        "to": "Kungsbacka",
        "distance": "72 km",
        "status": "I Varberg bygger man just nu dubbelspår fr järnvägen. Delar av det nya järnvägsspåret ska läggas i tunnel under staden, en ny station och en ny bro ska byggas med mera. Ett stort och spännande projekt som tidvis kommer påverka dig som cyklar på Kattegattleden frbi eller i Varberg. Kommer du med cykeln på tåget, så håll hger när du gått ur från tågstationen och flj sedan hänvisningsskyltarna ner till leden.",
        "foodAndDrinks": "",
        "information": "Här cyklar du längs kusten, genom skogsmark och över jordbrukslandskap, genom små samhällen och i stadsmiljö. Leden är lätt kuperad där den följer småvägar och cykelvägar samt trafikerad vägar. Norr om Varberg förändras landskapet – sandstränder blir till klippor och de första öarna börjar dyka upp: Getterön, Balgö och Vendelsö. Leden tar dig ner till Bua hamn, ett fiskeläge där du kan prova på havets läckerheter. Genom Frillesås går leden i blandtrafik och fortsätter sedan på den gamla banvallen där du kan njuta av en bilfri sträcka längs kusten. Stanna till vid något av havsbaden i Åsa, ta en glass i caféet eller en eftermiddagsfika på bageriet. Gör en avstickare till Tjolöholms slott, Sveriges enda slott i tudorstil. Du cyklar vidare längs åkrar och hästgårdar. Stanna till på Rolfsbron i Hanhals, en vacker stenvalvsbro från 1600-talet. I Kungsbacka går leden genom staden som är en av Västsveriges största handelsplatser.",
        "information2": Tr("route_7_info"),
        "surface": Tr("surface_7")
      },
      {
        "route": "route_7",
        "name": "KUNGSBACKA - GÖTEBORG",
        "from": "Kungsbacka",
        "to": "Göteborg",
        "distance": "64 km",
        "status": "Det finns inga kända problem längs etappen.",
        "foodAndDrinks": "",
        "information": "Kattegattleden mellan Kungsbacka och Göteborg bjuder på vackra naturupplevelser. Innan du möts av Göteborgs myllrande stadsliv cyklar du längs havet bland klippor och småbåtshamnar  Kattegattleden rullar vidare längs Kungsbackafjorden. På Båt- och Sjöfartsmuséet, strax nedanför kyrkan i Onsala, kan du få veta allt om ”Lasse i Gatan”, kungens kapare Lars Gathenhielm, som föddes år 1689. För öppettider se hemsidan.   Åk ner till Gottskär, det lilla samhället vid fjorden. Här kan du bada från salta klippor eller hoppa från hopptornet på Utholmen, strosa längs hamnen och insupa atmosfären. Du kan njuta av en god glass i något av glasscaféerna eller äta en bit mat på någon av de mysiga restaurangerna och caféerna runt Hamnplan eller varför inte ta in en natt på hotellet. I Särö roade sig kungligheter och Göteborgssocieteten under förra seklet. Gör en avstickare till Särö Västerskog där du kan promenera längs havet. När du lämnat badorten cyklar du på gamla Säröbanans banvall som går längs havet. Klippor, lummig skog, vikar och småbåtshamnar kantar din väg och här finns gott om badplatser.  Närmare Göteborg går leden förbi Nya Varvet, klippans kulturreservat och Röda Stens Konsthall. Vid Järntorget viker du in mot Göteborg city, passerar picknickvänliga Kungsparken och Stora Teatern. Sista sträckan går utmed Trädgårdsföreningen, en av Europas bäst bevarade 1800-talsparker, innan du når slutmålet på Drottningtorget vid Centralstationen.",
        "information2": Tr("route_8_info"),
        "surface": Tr("surface_8")
      }
    ]
}

export default routeData;

