import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
//import TagManager from 'react-gtm-module'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "bikes-b814c.firebaseapp.com",
  projectId: "bikes-b814c",
  storageBucket: "bikes-b814c.appspot.com",
  messagingSenderId: "495074880691",
  appId: "1:495074880691:web:994e9a2bebe3e4fa35d4c6",
  measurementId: "G-22HGWJ8JGJ"
};

// const tagManagerArgs = {
//   gtmId: 'GTM-K32642S2'
// }
// TagManager.initialize(tagManagerArgs)

export const fireBaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(fireBaseApp);