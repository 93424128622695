"use client";
import { useState, useEffect } from 'react';
import { Map, APIProvider, Pin, AdvancedMarker, useMapsLibrary, useMap, InfoWindow } from '@vis.gl/react-google-maps'
import gpxURL from '../../data/Kattegattleden_SouthNorth_stripped.gpx';
import './Map.css';
import MarkerWithInfoWindow from '../../components/MarkerWithInfoWindow';
import towns from '../../data/towns';

const MapView = ({ hotelsInfo, from, to }) => {
  const [zoom, setZoom] = useState(7.5);
  const [center, setCenter] = useState({ "lat": 56.962322, "lng": 12.409183 });
  const [gpxPath, setGpxPath] = useState([]);
  
  const getTownLatLong = (townName) => {
    return towns.find(town => town.name === townName).coordinates;
  };

  const fromTownLatLong = getTownLatLong(from);
  const toTownLatLong = getTownLatLong(to);

  useEffect(() => {
    fetch(gpxURL)
    .then(response => response.text() )
    .then(str => (new window.DOMParser()).parseFromString(str, "text/xml"))
    .then(doc => {
      const nodes = [...doc.getElementsByTagName('trkpt')];
      const paths = nodes.map((gpxPoint) => {
        const lat = parseFloat(gpxPoint.getAttribute('lat'));
        const lng = parseFloat(gpxPoint.getAttribute('lon'));
        return { lat, lng };
      });
      setGpxPath(reconstructPaths(paths));
    })
  }, []);

  const reconstructPaths = (paths) => {
    for (let i = 0; i < paths.length; i++) {
      let startIndex = -1;
      let endIndex = -1;
      for (let i = 0; i < paths.length; i++) {
        if (paths[i].lat === fromTownLatLong.lat && paths[i].lng === fromTownLatLong.lng) {
          startIndex = i;
        }
        if (paths[i].lat === toTownLatLong.lat && paths[i].lng === toTownLatLong.lng) {
          endIndex = i;
        }
        if (startIndex !== -1 && endIndex !== -1) {
          break;
        }
      }
      if (startIndex !== -1 && endIndex !== -1) {
        return paths.slice(startIndex, endIndex + 1);
      }
    }
    return paths;
  }

  const handleZoomChanged = (newZoom) => {
    setZoom(newZoom)
  };

  const handleCenterChanged = (newCenter) => {
    setCenter({
      lat: newCenter.detail.center.lat,
      lng: newCenter.detail.center.lng
    });
  };

  return (
    <div>
      <APIProvider apiKey={process.env.REACT_APP_MAPS_KEY}>
        <div className="map-container">
          <Map
            zoom={zoom}
            center={center}
            mapId={"54605993d9b22327"}
            onZoomChanged={handleZoomChanged}
            onCenterChanged={handleCenterChanged}
          >
            <MapContent nodes={gpxPath} />
            {hotelsInfo && hotelsInfo.map((info, index) => (
              <MarkerWithInfoWindow key={index} hotelInfo={info} /> 
            ))}
          </Map>
        </div>
      </APIProvider>
    </div>
  );
}

const MapContent = ({ nodes }) => {
  const maps = useMapsLibrary("maps");
  const map = useMap();
  
  useEffect(() => {
    if (!maps || !map) return;
    const polylines = new maps.Polyline({
      path: nodes,
      geodesic: true,
      strokeColor: "#B31212",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    polylines.setMap(map);
  }, [maps, map, nodes]);

  return null;
};

export default MapView;