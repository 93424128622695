import { useState, useEffect } from "react";
import "./BikeReservation.css";
import CartItem from "../../components/CartItem";
import { otherItemsData } from "../../data/cart_item_data";
import PopupInfo from "../../components/Popups/PopupInfo";
import PopupBookingReservation from "../../components/Popups/PopupBookingReservation";
import BackButton from "../../components/buttons/BackButton";
import { Tr, TrWithParams } from "../../../translations/translation";
import { formatDateForDisplay } from "../../components/Date/DateHandler";

const OtherReservation = ({
  onBikeReservationFinished,
  onBack,
  bookingData,
  setBookingData,
}) => {

  const [totalOther, setTotalOther] = useState(0);
  const [otherData, setOtherData] = useState(bookingData.otherData ? bookingData.otherData : otherItemsData);
  const [bookingPopupOpen, setBookingPopupOpen] = useState(false);

  useEffect(() => {
    {bookingData.otherData && bookingData.otherData.map((item) => {
      setOtherAndTotal(item.name, item.quantity)
    })}
  }, []);

const setOtherAndTotal = (index, value) => {
  setOtherData((prevData) => {
    const newData = prevData.map((item) =>
      item.name === index ? { ...item, quantity: value } : item
    );
    const total = newData.reduce(
      (acc, item) => acc + calculatItemPrice(item) * item.quantity,
      0
    );
    setTotalOther(total);
    return newData;
  });
}

  const handleInputChange = (e) => {
    console.log("handleInputChange", e.target.id, e.target.value);
    const index = e.target.id;
    const value = parseInt(e.target.value, 10); // Ensure the value is an integer
    setOtherAndTotal(index, value)
    setAllBookingData();
  };

  const handleContinuePressed = () => {
    if (totalCost() === 0) {
      onBikeReservationFinished();
    }
    setBookingPopupOpen(true);
    setAllBookingData();
  };

  const setAllBookingData = () => {
    setBookingData((prevEmailData) => ({
      ...prevEmailData,
      otherData: otherData,
      totalCost: totalCost(),
      hasBaggageTransport: hasBaggageTransport(),
    }));
  };

  const hasBaggageTransport = () => {
    const item = otherData.some(
      (item) => item.name === Tr("baggage_transport")
    );
    return item.quantity > 0;
  };

  const handleBackPressed = () => {
    setAllBookingData();
    onBack(1);
  };

  const totalCost = () => {
    return bookingData.bikesCost + totalOther;
  };

  const onFinishedBooking = () => {
    onBikeReservationFinished();
  };

  const calculatItemPrice = (product) => {
    if (product.calculationType === "fixed") {
      return product.price;
    }
    return bookingData.numberOfDays * product.price;
  };

  return (
    <div className="max-w-lg mx-auto p-2">
      <div className="">
        <BackButton onBackPressed={handleBackPressed} />
        <div className="mb-4">
          <PopupInfo type="reserv" contentStyle={{ width: "80%" }} />
        </div>
        <h1 className="text-xl font-bold">{Tr("transport_title")}</h1>
      </div>
      <p className="text-sm mt-2">
        {TrWithParams(
          "your_booking_for",
          bookingData.adults,
          formatDateForDisplay(bookingData.startDate),
          formatDateForDisplay(bookingData.endDate)
        )}
      </p>
      <form className="mt-4">
        <div className="mb-4">
          <label className="block text-lg font-bold mb-2">
            {Tr("accessories")}:
          </label>
          {otherData.map((product, index) => (
            <div key={index}>
              {index === 3 ? (
                <label className="block text-lg font-bold mb-2">
                  {Tr("addons")}:
                </label>
              ) : null}
              <CartItem
                image={product.image}
                name={product.name}
                price={calculatItemPrice(product)}
                value={product.quantity}
                priceType={product.priceType}
                onPriceChange={handleInputChange}
              />
            </div>
          ))}
        </div>
        <div className="flex flex-col mb-4">
          <div className="flex justify-between">
            <span className="text-normal">{Tr("total_bike_cost")}: </span>
            <span className="font-normal">
              {bookingData.bikesCost} {Tr("currency")}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="text-normal">{Tr("total_addons")}: </span>
            <span className="font-normal">
              {totalOther} {Tr("currency")}
            </span>
          </div>
          <br />
          <div className="flex justify-between mb-4">
            <span className="text-lg font-bold">{Tr("total")}: </span>
            <span className="text-lg font-bold">
              {totalCost()} {Tr("currency")}
            </span>
          </div>
        </div>
        <div className="flex ">
          <button
            type="button"
            className="submit-button close-button "
            onClick={handleContinuePressed}
          >
            {Tr("continue")}
          </button>
          {bookingPopupOpen && (
            <PopupBookingReservation
              bookingData={bookingData}
              onFinishedBooking={onFinishedBooking}
              isOpen={bookingPopupOpen}
              setIsOpen={setBookingPopupOpen}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default OtherReservation;
