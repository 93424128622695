import { format } from "date-fns";
import sv from "date-fns/locale/sv";
import de from "date-fns/locale/de";
import en from "date-fns/locale/en-GB";

const getLocale = () => {
  const language = localStorage.getItem('selectedLanguage');
  if (language === 'sv') {
    return sv;
  } else if (language === 'de') {
    return de;
  } else {
    return en;
  }
}

const formatDateForDisplay = (date) => {
  return format(date, "EEEE d MMMM, yyyy", { locale: getLocale() }); 
};

const formatDateShortForDisplay = (date) => {
  return format(date, "dd MMM -yy", { locale: getLocale() });
};

const formatDateForAPI = (date) => {
  return format(date, "yyyy-MM-dd")
};

export { formatDateForDisplay, formatDateForAPI, formatDateShortForDisplay };