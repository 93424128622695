import backUrl from '../../../img/svg/back_arrow.svg';
import './BackButton.css';
import { Tr } from '../../../translations/translation';

const BackButton = ({ onBackPressed }) => {
  return(
    <button className="back-button flex" onClick={onBackPressed}>
      <img className='back-button-img' src={backUrl} alt="back" />
    <label className='back-button-text'>{Tr('back')}</label>
    </button>
  )
}

export default BackButton;