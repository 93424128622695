import { useState, useEffect } from 'react';
import './BikeReservation.css';
import CartItem from '../../components/CartItem';
import PopupInfo from '../../components/Popups/PopupInfo';
import ErrorPopup from '../../components/Popups/ErrorPopup';
import BackButton from '../../components/buttons/BackButton';
import { bikesItemData } from '../../data/cart_item_data';
import { formatDateForDisplay } from '../../components/Date/DateHandler';
import { validateEmailField, validatePhoneField, validateNameField } from '../../components/utils/Validators';
import { Tr, TrWithParams } from '../../../translations/translation';
import { bikeReservationName, bikeReservationPhone, bikeReservationEmail } from '../../components/TestHelper';

const BikeReservation = ({ bookingData, setBookingData, onSkip, onContinue, onBack }) => {
  const [totalCostBikes, setTotalCostBikes] = useState(0);
  const [bikesData, setBikesData] = useState(bikesItemData);
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [checkBox, setCheckBox] = useState(true);

  useEffect(() => {
    setName(bookingData.name ? bookingData.name : bikeReservationName());
    setPhone(bookingData.phone ? bookingData.phone : bikeReservationPhone());
    setEmail(bookingData.email ? bookingData.email : bikeReservationEmail());
    setBikeQuantityFromBookingData();
  }, []);

  const setBikeQuantityFromBookingData = () => {

    if (!bookingData.bikesData) {
      return;
    }

    setUIData(bookingData.bikesData, bookingData.pickupFee);
    setBikesData(bookingData.bikesData)
    setAllBookingData()
  }

  const setAllBookingData = () => {
    setBookingData({
      ...bookingData,
      bikesData: bikesData,
    })
  }

  const onSkippedClicked = () => {
    onSkip();
  }

  const onContinueClicked = (event) => {
    event.preventDefault();
    const validationData = {
      ...bookingData,
      bikesData: bikesData,
      bikesCost: totalCostBikes,
      comment: comment,
      name: name,
      phone: phone,
      email: email
    }
    setBookingData(validationData)
    if (validateFields(validationData)) {
      onContinue();
    }
  }

  const validateFields = (validationData) => {
    if (!validateEmailField({ email, setErrorMessage })
      || !validatePhoneField({ phone, setErrorMessage })
      || !validateNameField({ name, setErrorMessage })) {
      return false;
    }

    if (!validateBikeHeightFields(validationData)) {
      return false;
    }

    return true;
  }

  const validateBikeHeightFields = (validationData) => {
    if (validationData.bikesData == null || bikesData.some(item => item.bikesInCart.some(bike => bike.height === 0))) {
      setErrorMessage(Tr("height_error"));
      return false;
    }

    if (bikesData.some(item => item.bikesInCart.some(bike => bike.height < 50))) {
      setErrorMessage(Tr("height_wrong_error"));
      return false;
    }

    if (bikesData.some(item => item.bikesInCart.some(bike => bike.height > 250))) {
      setErrorMessage(Tr("height_wrong_error"));
      return false;
    }
    return true;
  }

  const handleBackPressed = () => {
    onBack(0);
  }

  const setUIData = (newData, isChecked) => {
    console.log("newData", newData)
    const totalCost = newData.reduce((acc, item) => acc + calculatBikePrice(item.price) * item.bikesInCart.length, 0);
    const totalPickupFee = calculatePickupFee(400);
    //console.log("newData.pickupFee", newData.pickupFee)
    setTotalCostBikes(totalCost + (isChecked ? totalPickupFee : 0));
  }

  const handleBikeCountChange = (e) => {
    const { id, value } = e.target;
    var newValue = parseInt(value, 10);
    if (isNaN(newValue) || newValue < 0) {
      newValue = null;
    }

    setBikesData(prevData => {
      const newData = prevData.map(item => {
        if (item.name === id) { 
          return {
            ...item,
            bikesInCart: newValue > item.bikesInCart.length
              ? [
                  ...item.bikesInCart,
                  ...Array(newValue - item.bikesInCart.length)
                    .fill()
                    .map((_, index) => ({
                      height: 0,
                      name: `${Tr("bike")} ${item.bikesInCart.length + index + 1} ${item.name}`,
                      id: randomId()
                    }))
                ]
              : item.bikesInCart.slice(0, newValue)
          };
        }
        return item;
      });
      setUIData(newData, bookingData.pickupFee);
      return newData;
    });
  }

  const handleCheckBoxChange = (e) => {
    const { id, checked } = e.target;
    console.log("handleCheckBoxChange", id, checked)
    console.log("bikesData", bikesData)
    setCheckBox(checked);
    setBookingData({
      ...bookingData,
      pickupFee: checked,
    })
    setUIData(bikesData, checked);
  }

  const randomId = () => {
    return Math.random().toString(36).substring(7);
  }

  const handleHeightChanged = (e) => {
    const { id, value } = e.target;
    var newValue = parseInt(value, 10);
    if (isNaN(newValue) || newValue < 0) {
      newValue = null;
    }
    setBikesData(prevData => {
      const newData = prevData.map(item => {
        item.bikesInCart.forEach(bike => {
          if (bike.id === id) {
            bike.height = newValue;
          }
        });
        return item;
      });
      setUIData(newData);
      return newData;
    });
  }

  const bikeSize = (cartBike, index) => {
    const labelName = Tr("bike") + " " + index + " " + cartBike.name
    return (
      <div className="flex items-center justify-between mb-2">
        <label>{labelName}:</label>
        <input
          className="text-field"
          type="number"
          id={cartBike.id}
          value={cartBike.height > 0 ? cartBike.height : ""}
          placeholder={Tr("height")}
          maxLength={3}
          onChange={handleHeightChanged}
        />
      </div>
    )
  }

  const bikeHeightViews = () => {
    const allBikesInCart = bikesData.reduce((acc, bike) => {
      return acc.concat(bike.bikesInCart.map(bikeInCart => ({ ...bikeInCart, name: bike.name, height: bikeInCart.height,  })));
    }, []);
    return (
      allBikesInCart.map((cartBike, index) => {
        return (
          <div key={index}>
            {bikeSize(cartBike, index + 1)}
          </div>
        )
      })
    )
  }

  const reservationInfoContainer = () => {
    return (
      <div className="mb-4">
        <label className="block text-lg font-bold mb-2">{Tr("reservation_info")}:</label>
        <label className="block text-md mb-2">{Tr("name")}:</label>
        <input value={name} type="text" className="text-field w-full p-2 mb-2" placeholder={Tr("name")} onChange={(e) => setName(e.target.value)} />
        <label className="block text-md mb-2">{Tr("phone")}:</label>
        <input value={phone} type="text" className="text-field w-full p-2 mb-2" placeholder={Tr("phone")} onChange={(e) => setPhone(e.target.value)} />
        <label className="block text-md mb-2">{Tr("email")}:</label>
        <input value={email} type="email" className="text-field w-full p-2" placeholder={Tr("email")} onChange={(e) => setEmail(e.target.value)} />
      </div>
    )
  }

  const calculatBikePrice = (price) => {
    var pr = bookingData.numberOfDays * price
    if (bookingData.numberOfDays < 6) {
      return pr
    }
    return pr - (price * 0.5)
  }

  const calculatePickupFee = (price) => {
    return bikesData.reduce((acc, bike) => {
      return acc + (bike.bikesInCart.length * price);
    }, 0);
  }

  const bikesContainer = () => {
    return (
      <div>
        <div className="mb-4">
          <label className="block text-lg font-bold mb-2">{Tr("bike")}:</label>
          {bikesData && bikesData.map((bike, index) => (
            <div key={index}>
              <CartItem
                image={bike.image}
                name={bike.name}
                price={bike.type === "bike" ? calculatBikePrice(bike.price) : calculatePickupFee(bike.price)}
                value={bike.bikesInCart.length}
                isPickupFee={bike.type === "pickup_fee"}
                priceType={bike.priceType}
                onCheckBoxChange={handleCheckBoxChange}
                onPriceChange={handleBikeCountChange}
                type={bike.type}
              />
            </div>
          ))}
        </div>
        <div className="mb-4">
          {totalCostBikes > 0 && <label className="block text-lg font-bold mb-2">{Tr("bike_size")}:</label>}
          {bikeHeightViews()}
        </div>
        <div className="flex justify-between mb-4">
          <span className="text-lg font-bold">{Tr("total_bikes")}: </span>
          <span className="text-lg font-bold">{totalCostBikes}{Tr("currency")}</span>
        </div>
      </div>
    )
  }
  
  return (
    <div className="max-w-lg mx-auto">
      {errorMessage.length > 0 && <ErrorPopup
        errorTitle={Tr("something_wrong")}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />}
      <div className="">
        <BackButton onBackPressed={handleBackPressed} />
        <div className='mb-4'>
          <PopupInfo type="reserv" contentStyle={{ width: "80%" }} />
        </div>
        <h1 className="text-xl font-bold">{Tr("reserve_bikes")}:</h1>
      </div>
      <p className="text-sm mt-2">{TrWithParams("your_booking_for", bookingData.adults ? bookingData.adults : 2, formatDateForDisplay(bookingData.startDate), formatDateForDisplay(bookingData.endDate))}</p>
      <form className="mt-4">

        {bikesContainer()}

        <div className="mb-4">
          <label className="block text-lg font-bold mb-2">{Tr("comment")}:</label>
          <textarea className="border rounded w-full p-2" rows="3" onChange={(e) => setComment(e.target.value)}></textarea>
        </div>

        {reservationInfoContainer()}

        <div className="flexbetween">
          <button type="submit" className="submit-button mr-8" onClick={onContinueClicked}>{Tr("continue")}</button>
          <button type="button" className="secondary-button" onClick={onSkippedClicked}>{Tr("skip")}</button>
        </div>
      </form>
    </div>
  );
};

export default BikeReservation;