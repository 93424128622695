import Main from './code/views/Main/Main';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DisplayResultView from './code/views/Main/Result/DisplayResultView';
import background from '../src/img/background_fade.jpeg';
import { Helmet } from 'react-helmet';
import { Tr } from './translations/translation';
import Footer from './code/views/Containers/Footer';
import './code/views/Common/Common.css';

const App = () => {
  return (
    <div className='container'>
      <img src={background} alt="west-coast" className='background' />
      <div>
        <h1 className='text-xxl font-bold title'>{Tr('h1_title')}</h1>
        <label className='text-lg subtitle'>{Tr('subtitle')}</label>
      </div>
      <Helmet>
          <title> {Tr('helmet_title')} </title>
        </Helmet>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path={`/show-bikeplan`} element={<DisplayResultView  />} />
          </Routes>
        </div>
      </Router>
      <Footer/>
    </div>
  );
};

export default App;
