import { useState, useCallback } from 'react';
import { Pin, AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';

const MarkerWithInfoWindow = ({key, hotelInfo}) => {
  // `markerRef` and `marker` are needed to establish the connection between
  // the marker and infowindow (if you're using the Marker component, you
  // can use the `useMarkerRef` hook instead).
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [infoWindowShown, setInfoWindowShown] = useState(false);

  // clicking the marker will toggle the infowindow
  const handleMarkerClick = useCallback(
    () => setInfoWindowShown(isShown => !isShown), []
  );

  // if the maps api closes the infowindow, we have to synchronize our state
  const handleClose = useCallback(() => setInfoWindowShown(false), []);
  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        position={hotelInfo.coordinates}
        onClick={handleMarkerClick}
      >

      <Pin
        key={key}
        background={"white"}
        borderColor={"var(--color-secondary)"}
        glyphColor={"var(--color-secondary)"}

      />
      </AdvancedMarker> 
      {infoWindowShown && (
        <InfoWindow anchor={marker} onClose={handleClose}>
          <h2>{hotelInfo.name}</h2>
          <a href={`https://www.google.com/maps/search/?api=1&query=${hotelInfo.coordinates.lat}%2C${hotelInfo.coordinates.lng}`} target="_blank">Öppna i Google Maps</a>
        </InfoWindow>
      )}
    </>
  );
};

export default MarkerWithInfoWindow;