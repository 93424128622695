import { useState, useRef } from 'react';
import './Main.css';
import MenusView from './Menus';
import townsInRange from '../../components/Popups/helpers/townsInRange';
import SecondContainerHandler from '../Containers/SecondContainerHandler';
import { Tr } from '../../../translations/translation';

const Main = () => {
  const [towns, setTowns] = useState();
  const [isOrdering, setIsOrdering] = useState(false);
  const secondContainerRef = useRef(null);

  const [menusData, setMenusData] = useState({
    startDate: '',
    endDate: '',
    adults: '',
    rooms: '',
    from: '',
    to: '',
    email: '',
    name: '',
  }); 

  const resetOrdering = () => {
    setIsOrdering(false);
  }
  
  const handleSubmit = () => {
    setIsOrdering(true);
    setTowns(townsInRange(menusData.from, menusData.to))
    setTimeout(() => {
      if (secondContainerRef.current) {
        secondContainerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  }

  return (
    <div className='main-container'>
      <div className='base-container form-container'>
        <MenusView setMenusData={setMenusData} />
        <br />
        <div className='submit-container'>
          <button className='submit-button' onClick={handleSubmit}>{Tr('calculate_route')}</button>
        </div>
        <br />
      </div>
      {isOrdering && <div className='base-container response-container' ref={secondContainerRef}>
          <SecondContainerHandler townsIn={towns} menuData={menusData} setIsOrdering={resetOrdering} />
      </div>}
    </div>
  );
}

const testResponse =
  `Njut av sista dagen på stranden eller utforska ytterligare sevärdheter
---///---
[
  { 
    "name": "Höganäs"
  },
  { 
    "name": "Ängelholm"
  },
  { 
    "name": "Båstad"
  }
]
---///---
`;

export default Main;
{/* <MenusView queryToSend={handleSetQuery} setMenusData={setMenusData} /> */}
// const { OpenAI } = require("openai");
// const [query, setQuery] = useState('');
// const openai = new OpenAI({
//   // apiKey: process.env.OPENAI_API_KEY
//   dangerouslyAllowBrowser: true,
//   apiKey: 
// });

// const handleSetQuery = (queryToSend) => {
//   setQuery(queryToSend)
// }
// const [response, setResponse] = useState('');
  // const extractTextAndJavaScriptArray = (response) => {
  //   const match = response.match(/(.*?)\-\-\-\/\/\/\-\-\-(.*?)\-\-\-\/\/\/\-\-\-/s);
  //   if (match) {
  //     return {
  //       text: match[1].trim(),
  //       jsonString: match[2].trim()
  //     };
  //   }
  //   return { text: '', javascriptArrayString: null };
  // };

  // const trimAndSetResponse = (response) => {
  //   const trimmedResponse = extractTextAndJavaScriptArray(response);
  //   if (trimmedResponse.jsonString) {
  //     try {
  //       const positionsArray = JSON.parse(trimmedResponse.jsonString);
  //       setTowns(positionsArray);
  //     } catch (error) {
  //       console.error('Error parsing JavaScript array:', error);
  //     }
  //   }

  //   if (trimmedResponse.text) {
  //     setResponse(trimmedResponse.text)
  //   }
  // }

    //trimAndSetResponse(testResponse)
    //setIsLoading(true)

    // const responseGenerate = async (query) => {
    //   const thread = await openai.beta.threads.create();
    //   const message = await openai.beta.threads.messages.create(
    //     thread.id,
    //     {
    //       role: "user",
    //       content: query,
    //     }
    //   );

    //   let run = await openai.beta.threads.runs.createAndPoll(
    //     thread.id,
    //     {
    //       assistant_id: "asst_Z1BK4kgZF3ObZd57C4F6kMC4",
    //     }
    //   );

    //   if (run.status === 'completed') {
    //     const messages = await openai.beta.threads.messages.list(
    //       run.thread_id
    //     );
    //     const msg = messages.data[0].content[0].text.value
    //     setIsLoading(false)
    //     trimAndSetResponse(msg)

    //   } else {
    //     console.log(run.status);
    //   }
    // };

    // responseGenerate(query);

// Dag 1: Höganäs till Mölle
// Avstånd: ca 20 km
// Sevärdheter:

// Kullabergs naturreservat
// Kullens fyr
// Boende: Hotel Kullaberg
// Dag 2: Mölle till Ängelholm
// Avstånd: ca 30 km
// Sevärdheter:

// Höganäs Keramik
// Krapperups slott
// Skälderviken
// Boende: Best Western Plus Hus 57
// Dag 3: Ängelholm till Båstad
// Avstånd: ca 35 km
// Sevärdheter:

// Hembygdsparken
// Båstad hamn
// Norrviken Trädgårdar
// Boende: Hotel Skansen Båstad
// Dag 4: Båstad till Halmstad
// Avstånd: ca 45 km
// Sevärdheter:

// Hallands Väderö
// Laholm
// Tylösand strand
// Boende: Hotel Tylösand
// Dag 5: Halmstad (vilodag)
// Aktiviteter:

// Besök Halmstad slott
// Galgberget med utsiktstornet
// Shoppa i Halmstad city
// Boende: Hotel Tylösand
// Dag 6: Halmstad till Haverdal
// Avstånd: ca 20 km
// Sevärdheter:

// Haverdals naturreservat
// Boende: Haverdals Camping & Vandrarhem (stugor)
// Dag 7: Haverdal till Ugglarp
// Avstånd: ca 30 km
// Sevärdheter:

// Steninge strand
// Särdals kvarn
// Boende: Ugglarp Camping (stugor)
// Dag 8: Ugglarp till Falkenberg
// Avstånd: ca 30 km
// Sevärdheter:

// Glommen
// Skrea strand
// Boende: Falkenberg Strandbad
// Dag 9: Falkenberg (vilodag)
// Aktiviteter:

// Utforska Gamla Stan
// Falkenbergs Museum
// Shoppa i Falkenberg centrum
// Boende: Falkenberg Strandbad
// Dag 10: Falkenberg (avslutning)
// Aktiviteter: