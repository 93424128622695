import routeData from '../../../data/route_data';

const routeDataForTown = (town) => {
  const routes = routeData.routes;
  var routeObject = routes.find((route) => route.from === town.name);
  if (!routeObject) {
    routeObject = routes.find((route) => route.to === town.name);
  }
  return routeObject;
}

export default routeDataForTown ;