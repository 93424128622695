import Popup from 'reactjs-popup';

import 'reactjs-popup/dist/index.css';

import crossButtonIconUrl from '../../../img/svg/cross_button_icon.svg';
import { bikesItemData, otherItemsData } from '../../data/cart_item_data';
import './PopupInfo.css';
import { Tr } from '../../../translations/translation';

const PopupCartInfo = ({ type, itemName, image, isOpen, setIsOpen }) => {
  const closeModal = () => setIsOpen(false);

  const data = () => {
    if (type === "bike" || type === "pickup_fee") {
      return bikesItemData
    }
    return otherItemsData
  }

  const infoContainer = () => {
    const itemData = data();
    const info = itemData.find(item => item.name === itemName);
    return (
      <div className='cart-content-container mb-6'>
        <br /><br />
        <div className='info-container'>
          <h2 className='text-xl font-bold'>{info.name}</h2>
          <p>{info.info}</p>
        </div>
        {image ? <img className='info-img' src={info.image} alt={info.name} /> : null}
      </div>
    );
  }

  return (
    <div >
      <Popup open={isOpen} closeOnDocumentClick onClose={closeModal} contentStyle={{width: "80%"}}>
        <div className='cart-item-container'>
          <a className="close mb-2" onClick={closeModal}>
            <img className='cross-button' src={crossButtonIconUrl} alt="cross" />
          </a>
          {infoContainer()}
        <button className='submit-button close-button' onClick={closeModal}>{Tr('close')}</button>
        </div>
      </Popup>
    </div>
  );
}

export default PopupCartInfo;
