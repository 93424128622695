import { useState } from 'react';
import { Tr } from '../../../../translations/translation';
import routeDataForTown from '../../../components/Popups/helpers/routeDataForTown';

const RouteView = ({ bookingData }) => {
  const [showMore, setShowMore] = useState([]);

  const dailyIntinerary = (index, town, startDate) => {
    if (index == bookingData.towns.length - 1) { //x towns but only x-1 overnights, return to not display extra night
      return;
    }
    const route = routeDataForTown(town);
    if (!route) {
      return;
    }
    const currentDayDate = new Date(startDate);
    currentDayDate.setDate(currentDayDate.getDate() + index);
    const nextDay = new Date(currentDayDate);
    nextDay.setDate(nextDay.getDate() + 1);
    return (
      <div>
        <h2 className='text-xl mt-3 mb-1'>Dag {index + 1}: {route.name}</h2>
        <div className='route-text-container'>
          <strong>{Tr("bike_distance")}:</strong> {route.distance}<br />
          <strong>{Tr("route_info")}:</strong> {route.information2 ? informationTextWithReadMore(route.information2, index, 120) : informationTextWithReadMore(route.information, index, 120)}  <br /><br />
        </div>
      </div>
    )
  }

  const informationTextWithReadMore = (text, index, letterCount) => {
    if (showMore.find(item => item.index === index)) {
      return text
    }
    const isOverCount = text.length > letterCount
    const newText = isOverCount ? text.slice(0, letterCount) + "..." : text;
    const isCurrenIndexOpen = { isOpen: true, index: index }
    return (
      <div className='inline-div'>
        {newText}
        {isOverCount && <button className='read-more-button' onClick={() => setShowMore([...showMore, isCurrenIndexOpen])}>Läs mer</button>}
      </div>
    )
  }

  return(
    <div>
    {bookingData.towns.length > 0 && bookingData.towns.map((town, index) => (
      <div key={index} >
        {dailyIntinerary(index, town, bookingData.startDate)}
      </div>
    ))}
  </div>
  );
}

export default RouteView;
