import './CartItem.css';
import PopupCartInfo from '../components/Popups/PopupCartItem';
import { Tr } from '../../translations/translation';
import InfoIcon from './icons/InfoIcon';
import { useState } from 'react';

const CartItem = ({ image, name, price, value, isPickupFee, onPriceChange, onCheckBoxChange, type, index }) => {
  const [open, setOpen] = useState(false);

  const bikeContainerButton = () => {
    return (
      <div className='flex items-center justify-between cart-item-left-container' onClick={() => setOpen(o => !o)}>
        <span className='cart-item-bike-name'>{name}:</span>
        <InfoIcon />
        {image ? <img src={image} alt={name} className="thumb-image" /> : null}
      </div>
    );
  }

  const priceInput = () => {
    return (
      <input 
        value={value > 0 ? value : ""} 
        type="number" 
        className="text-field text-field-number" 
        onChange={onPriceChange} 
        id={name} 
        min="0" 
      />
    );
  }

  const bikeFeeCheckBox = () => {
    return (
      <input type="checkbox" className="checkbox" onChange={onCheckBoxChange} id={name} />
    );
  }

  return (
    <div key={index} className="flex items-center justify-between mb-2">

      {bikeContainerButton()}
      <PopupCartInfo type={type} itemName={name} image={image} isOpen={open} setIsOpen={setOpen} />
      <div>
        <span className='price'>{price}{Tr("currency")}</span>
        {isPickupFee ? bikeFeeCheckBox() : priceInput()}
      </div>
    </div>
  )
}

export default CartItem;